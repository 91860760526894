import React, { useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { NewWindow, PrintNow } from '../../Common/home-common';
import { getFootballHelpUrl } from './Common';
import { toDateFormat } from '../Common/Common';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { GlobalContext } from '../../../contexts/GlobalContext';

import { TournImage } from './MatchBase'

const ExpandUI = ({handleClickExpand, isOpenTable}) => {
    return <div className={`arrow-area `} onClick={handleClickExpand}>
        <ArrowIcon className='hueRotate' direction='down' change={isOpenTable} changeDirection='up' size={20} />
    </div>
}

const TitleText = ({context}) => {
    const { t, i18n } = useTranslation();
    if (['TOURNAMENT', 'TOURNRES'].includes(context.state.page)) {
        let tourn = context.content.tournData?.tournaments?.[0];
        if (tourn != null) {
            let code = tourn.code;
            let name = tourn['name_' + i18n.language];
            let frontEndId = 'TOURNRES'===context.state.page ? tourn.frontEndId : '';
            return <span>{frontEndId} <TournImage code={code} name={name} /> {name}</span>
        }
        return null;
    }

    return t('LB_FB_TITLE_' + context.state.page)
}

const HelpBtn = ({context}) => {
    const { t, i18n } = useTranslation();
    const helpOnClick = (e) => {
        e.stopPropagation()
        NewWindow(getFootballHelpUrl(context.content.page, i18n.language), 'specialSite', 800, 600, 1, 1)
    }
    return <div className="HelpBtn" onClick={helpOnClick} title={t('LB_FB_HELP_ALT')} />
}

const SearchBtn = ({handleClickSearchBtn}) => {
    const { t, i18n } = useTranslation();
    return <div className='searchBtn-container' onClick={handleClickSearchBtn}>
        <i className='icon-search'></i>
        <span>{t('LB_M6_RESULTS_SEARCH')}</span>
    </div>
}

function RefreshBtn({context}) {
    let betType = context.content.betType;
    const onclick = (e)=> {
        if(!e.target?.classList.contains('rotate')) {
            e.target?.classList?.add('rotate')
            setTimeout(() => {
                e.target?.classList?.remove('rotate')
            }, 1200)
        }
        context.state.reloadPage()
    }
    return <div className="flex-center refresh-odds" onClick={onclick}>
        <div className="refreshIcon" />
        {/* <label>{t('LB_RC_REFRESH' + (!['TURNOVER', 'RESULTS'].includes(betType) ? '_ODDS' : ''))}</label> */}
    </div>
}

const PrintBtn = () => {
    const { t, i18n } = useTranslation();
    return <div className='printBtn-container' onClick={PrintNow} >
        <div className='printBtn-image' />
        <a >{t('LB_PRINT')}</a>
    </div>;
}

const FootballTitle = ({ title, isPoolDefined, timeStr, controlOpen, isOpenTable, setIsOpenTable }) => {
    const context = useContext(FootballContext);
    const {sessionServerTimeStamp} = useContext(GlobalContext);
    const { t, i18n } = useTranslation();
    let messgeSuffix = context.state.windowSize.isMobileSize ? ' - Mobile2' : ' - Desktop2';
    let messageObj = context.betTypeMessages?.filter(x => x.enable.value==="1" && x.key===context.content.page+messgeSuffix)[0];
    if ( !messageObj ) {
        messgeSuffix = context.state.windowSize.isMobileSize ? ' - Mobile' : ' - Desktop';
        messageObj = context.betTypeMessages?.filter(x => x.enable.value==="1" && x.key===context.content.page+messgeSuffix)[0];
    }
    const rcRefreshTime = useRef('');

    const handleClickSearchBtn = () => {
        const ele = document.documentElement
        ele.scrollTop = document.querySelector('#SearchBox').offsetTop
    }

    useEffect(() => {
        let dt = new Date(sessionServerTimeStamp);  // don't know why moment() keep the same time, so use new Date() instead
        dt.setTime(dt.getTime() + context.content.offset * 1000);
        rcRefreshTime.current = moment(dt).format('DD/MM/YYYY HH:mm');
    });

    const handleClickExpand = () => {
        setIsOpenTable(!isOpenTable)
    }

    if (timeStr) {
        rcRefreshTime.current = timeStr
    }

    return (<>
        <div className={`fbTitle ${controlOpen && 'resultOpenTitle'}`}>
            <div id="mtgInfoDV">
                <div id="lblPageName">
                    {controlOpen && <ExpandUI handleClickExpand={handleClickExpand} isOpenTable={isOpenTable} />}
                    {title || <TitleText context={context} />}
                    {context.content.pageConfig?.showHelpBtn && <HelpBtn context={context}/>}
                    {isPoolDefined && <SingleMessage betType={context.content.betType} />}
                </div>

                {isPoolDefined ?
                    <div className="right form-check">
                        {context.content.pageConfig?.showPrintBtn && context?.state?.windowSize?.isMobileSize !== true && <PrintBtn />}
                        {context.content.pageConfig?.showRefreshTime ? <div className="fb-refresh-time" >{t('LB_LASTUPDATE')} {rcRefreshTime.current}</div> : null}
                        {context.content.pageConfig?.showRefreshBtn && <RefreshBtn context={context} />}
                        {context.content.pageConfig?.showSearchBtn && <SearchBtn handleClickSearchBtn={handleClickSearchBtn} />}
                    </div> : null}
            </div>
        </div>
        { messageObj && messageObj.message.value!='' && <div className="crsRemarks" dangerouslySetInnerHTML={{ __html: messageObj.message.value }}></div> }
    </>)
}

function Content(props) {
    const {
        context,
        formatHeaderAllupLegs,
        formatAllupLegs,
    } = props;
    const { t, i18n } = useTranslation();
    const isMobileSize = context.state.windowSize.isMobileSize;

    let pageName = context.content.page.toUpperCase();
    let betValue = context.content.odds;
    let legstext = '';

    var _isMix = (pageName == "MIXALLUP" || pageName == "MIXALLUPSHORTCUT");

    if (pageName !== "INPLAY_ALL" && !isMobileSize) {
        let tmpOddsType = props.betType;
        if (pageName === "RESULT") {
            tmpOddsType = tmpOddsType.replace("INPLAY", "");
        } else if (pageName === "INPLAY") {
            tmpOddsType = tmpOddsType.replace("HAD", "INPLAY");
        } else if (pageName === 'OFM') {
            tmpOddsType = tmpOddsType.replace("HAD", "OFM");
        } else {
            legstext = formatHeaderAllupLegs(tmpOddsType);
        }
        switch (tmpOddsType.toUpperCase()) {
            case "CRS":
            case "FGS":
            case "HFT":
            case "FCS":
                if (_isMix) {
                    legstext = "[" + formatAllupLegs(tmpOddsType, "short") + "]";
                }
                break;
            case "MSP":
                legstext = '[' + t('LB_FB_SINGLE_ONLY') + ']';
                break;
            case "TPS":
                legstext = "";
                break;
            default:
                //legstext = legs2to8;
                if (_isMix) {
                    legstext = "";
                }
                break;
        }

        return <div className='legstext'>{legstext}</div>;
    }
    return <div></div>;
}

export const SingleMessage = (props) => {

    const context = useContext(FootballContext);
    const { t } = useTranslation();

    function poolIsAllUpOnly(_oddsType) {
        return false;
    }

    function formatHeaderAllupLegs(_oddsType) {
        let legstext = "";

        if (context.content.page === "MIXALLUP_DETAIL" && _oddsType !== "MIXALLUP_DETAIL") {
            legstext = formatAllupDetailLegs(_oddsType, "");
        } else if (formatAllupLegs(_oddsType, "").trim() != "") {
            if (!poolIsAllUpOnly(_oddsType)) {
                legstext = "[" + t('LB_FB_SINGLE') + "+"
                    + t('LB_ALLUP')
                    + "(" + formatAllupLegs(_oddsType, "") + ")]";
            } else {
                legstext = "[" + t('LB_ALLUP_ONLY')
                    + "(" + formatAllupLegs(_oddsType, "") + ")]";
            }
        }

        return legstext;
    }

    function formatAllupDetailLegs(_oddsType, _type) {
        var legsNo = context?.content?.config?.allupInfo[_oddsType];

        switch (_oddsType) {
            case "CRS":
            case "FCS":
            case "HFT":
                return "[" + t('LB_FB_LEGS_'+legsNo) + t('LB_FB_LEGS') + "]";

            default:
                return "";
        }

    }
    function formatAllupLegs(_oddsType, _type) {
        var otype = props.betType;

        switch (_oddsType) {
            case "FHAD":
                otype = "FHA";
                break;
            case "HILO":
                otype = "HIL";
                break;
            case "CHLO":
                otype = "CHL";
                break;
            case "FHLO":
                otype = "FHL";
                break;
            case "HHAD":
                otype = "HHA";
                break;
        }

        var legsNo = context?.content?.config?.allupInfo[_oddsType];

        var legsNoStr = (legsNo != null && legsNo != "" && legsNo != "0") ? t("LB_FB_LEGS_" + legsNo) : "";

        switch (_type.toLowerCase()) {
            case "short":
                return legsNoStr + t('LB_FB_LEGS');
            case "mix_check":
                if (legsNo != "8" && legsNo != "" && legsNo != "0") {
                    return "[" + legsNoStr + t('LB_FB_LEGS') + "]";
                } else {
                    return "";
                }
            case "js":
                var n = parseInt(legsNo);
                if (isNaN(n)) {
                    return "0";
                } else {
                    return legsNo;
                }
            default:
                if (legsNo == null || legsNo == "0") {
                    return "";
                } else {
                    return t("LB_FB_LEGS_UP_TO").replace("{0}", legsNoStr);
                }
        }
    }

    const newprops={...props,context,formatHeaderAllupLegs,formatAllupLegs}

    return <Content {...newprops} />
}

export default FootballTitle;