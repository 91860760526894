import moment from 'moment';

export const getBetSlipTopics = (betBasketsRef) => {
    if (!window.bsConfig || !window.fbConfig) return false;
    let topics = [];
    betBasketsRef.current?.forEach((betLines) => {
        betLines?.forEach((betLine) => {
            let curTopics = [];
            if (betLine.prod == 'HR') {
                curTopics = getRacingTopics(betLine);
            } else if (betLine.prod == 'FB') {
                curTopics = getFootballTopics(betLine);
            }
            betLine.topics = [...new Set(curTopics)].sort();
            topics = [...topics, ...curTopics];
        });
    });
    return [...new Set(topics)].sort();
};

const getRacingTopics = (betLine) => {
    const { betObj } = betLine;
    const { betType } = betObj;
    let poolList = [];
    if (['JKC', 'TNC'].includes(betType)) {
        let pushStr = '';
        const {
            pushTopics: { RACING_FO_ODDS }
        } = window.bsConfig;
        const {
            id,
            currentPool: { poolId },
            venueid
        } = betObj;
        const dt = id.split('_')[1];
        pushStr = RACING_FO_ODDS.replace('{dt}', dt)
            .replace('{ve}', venueid.toLowerCase())
            .replace('{btyp}', betType.toLowerCase())
            .replace('{poolId}', poolId);
        poolList.push(pushStr);
    }
    return poolList;
};
const getFootballTopics = (betLine) => {
    const { betObj } = betLine;
    const { betType } = betObj;

    let poolList = [];
    let msgStr = [];
    if (betType == 'ALUP') {
        betLine.betObj.betList?.forEach((item) => {
            msgStr = footBallSingleBet(item);
            poolList = [...poolList, ...msgStr];
        });
    } else {
        msgStr = footBallSingleBet(betObj);
        poolList = [...poolList, ...msgStr];
    }
    return poolList;
};

const footBallSingleBet = (betObj) => {
    const { AllTournamentOrder } = window.fbConfig;
    const {
        pushTopics: { MATCH_POOL_UP, TOURNAMENT_POOL_UP, MATCH_UP, TOURNAMENT_UP }
    } = window.bsConfig;
    const { combs, matchInfo, tournInfo,  matchId } = betObj;
    const tournId = matchInfo?.tournament ? matchInfo?.tournament?.id : tournInfo ? tournInfo?.id : matchId;
    let betList = [];
    if (matchInfo) {
        betList.push(footBallPoolReplace(MATCH_UP, { tournId, matchId: matchInfo.id }));
    }
    if (tournInfo) {
        betList.push(footBallPoolReplace(TOURNAMENT_UP, { tournId: tournInfo.id }));
    }
    combs.forEach((comb) => {
        const { poolId, matchId, betType } = comb;
        const params = {
            tournId,
            betType,
            poolId,
            matchId
        };
        if (AllTournamentOrder.includes(betType)) {
            betList.push(footBallPoolReplace(TOURNAMENT_POOL_UP, params));
        } else {
            betList.push(footBallPoolReplace(MATCH_POOL_UP, params));
        }
    });
    return betList;
};

const footBallPoolReplace = (poolStr, { tournId, betType, poolId, matchId }) => {
    return poolStr
        .replace('{tournId}', tournId)
        .replace('{matchId}', matchId)
        .replace('{btyp}', betType?.toLowerCase())
        .replace('{poolId}', poolId);
};

export const hasHideOdds = (betLine, newPool) => {
    return false;
};

const showDiffTime = 2;
export const getOddsArrowCss = (comb, hide) => {
    if (hide) return '';
    const { oddsVal, oldOdds, oddsUpdTs } = comb;
    if (isNaN(oldOdds) || isNaN(oddsVal) || !oddsUpdTs) {
        return '';
    }
    const oddsDiff = parseFloat(oddsVal) - parseFloat(oldOdds);
    const now = moment();
    const showDiff = oddsDiff != 0 && now.diff(oddsUpdTs, 'second') <= showDiffTime * 60;
    if (showDiff) {
        if (oddsDiff > 0) return 'oddsUp';
        else if (oddsDiff < 0) return 'oddsDown';
    }
    return '';
};

export const isDimBetLine = (st) => {
    return ['SUSPENDED', 'CLOSED'].includes(st);
};

export const isAvailableBetLine = (st) => {
    return st === 'SELLING' || st === undefined;
};

export const getBetLineCombSt = ({ pool, comb, isNormalStatus, isInteractiveServiceAvailable }) => {
    let sellStatus = 'CLOSED';
    if (isNormalStatus) {
        switch (pool?.status) {
            case 'SELLINGSTARTED':
                if (comb?.status == 'UNAVAILABLE' || !isInteractiveServiceAvailable) {
                    sellStatus = 'SUSPENDED';
                } else {
                    sellStatus = 'SELLING';
                }
                break;
            case 'SELLINGSTOPPED':
            case 'HOLD':
                sellStatus = 'SUSPENDED';
                break;
        }
        return sellStatus;
    }
    return sellStatus;
};

export const isRefundOrEliminated = (sellStatus, newCombination, poolStatus) => {
    if(!poolStatus|| !sellStatus || !newCombination) return
    let selectionSt;
    if (sellStatus == 'CLOSED') {
        if (['ELIMINATED', 'IRRATIONAL'].includes(newCombination?.status)) {
            selectionSt = 'ELIMINATED';
        } else if (newCombination.status.includes('REFUND') && !poolStatus.includes('REFUND')) {
            selectionSt = 'REFUND';
        }
    }

    return selectionSt;
};

export const allupCombCompare = (a, b) =>{
        if(a.combId != b.combId) return  a.combId - b.combId;
        const aMain = a.currentPool.lines.find(i => i.lineId == a.lineId).main
        const bMain = b.currentPool.lines.find(i => i.lineId == b.lineId).main
    
        if(aMain && !bMain){
            return -1
        }else if(!aMain && bMain ){
            return 1
        }
        return a.oddsVal - b.oddsVal
}
