import React, { useEffect, useContext, useRef, useState, useCallback, useLayoutEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { GetChannelPara } from '../../Common/ChannelParaFunc';
import AddSlipBtn,{AddFBAllupCalcSlipBtn} from '../../BetSlip/AddSlipBtn'
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { alupFormulaItem } from '../../Common/GlobalFunc';
import { getAllUpCalEntries, setAllUpCalEntries, addAllUpCalEntry, removeAllUpCalEntry,
    getHandicapResultItems, getGoallineResultItems } from './AddBet';
import AllupDetailCalPanel from '../Common/AllupDetailCalPanel';
import { getPersonalSettings } from '../../Home/PersonalSettings/config'
import { WebTVIcon } from './MatchBase';
import calculateSvg from '../../../info/Football/info/images/calculate.svg'
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import useIntersectionObserver from '../../Common/hooks/useIntersectionObserver';
import { EqualHeight, EqualHeightElement } from '../../Common/ui-components/EqualHeight';
import { getNumberSuffix, getOddsArrowCss } from './MatchBase';
import { isCRSOtherSels } from './CRSTable';
import moment from 'moment';

const AllupCalculator = (props) => {

    const context = useContext(FootballContext);
    const { t, i18n } = useTranslation();
    const initUnitBet = (sessionStorage.getItem('__extendDivCalUnitBet') && Number(sessionStorage.getItem('__extendDivCalUnitBet')) )|| parseInt(getPersonalSettings('football', 'ALUP'), 10);
    const [allupData, setAllupData] = useState({
        isExpand : false,
        unitBet: initUnitBet,
        noOfComb: '-',
        dividend: '-',
        netReturn: '-',
        alupFormula: '',
        alertMsg: ''
    });

    const initTimeRef = useRef(moment())

    const [updateLayout, triggerUpdate] = useState(null);
    let isMobile = context.state.windowSize.isMobileSize;

    useEffect(() => {
        let fbALlupData = getAllUpCalEntries();
        if (fbALlupData.length > 0) {
            if(!isMobile){
                allupData.isExpand = true;
            }
            updateAllUpData();
            setAllupData({...allupData});
        }
    }, []);

    useEffect(() => {
        let isClickOddsVal=context.tickToCalc;
        if(!!isClickOddsVal && !allupData.isExpand){
            allupData.isExpand = true;
            setAllupData({...allupData});
        }
    }, [context.tickToCalc]);

    useEffect(()=>{
        calculate()
    })

    useEffect(() => {
        let data = context.content.allupCalData;
        if (data == null) {
            return;
        }

        let fbALlupData = getAllUpCalEntries();
        let isMatchExists = fbALlupData.filter(x => x.id === data.id).length > 0;
        let hasMixAllup6LegPools = fbALlupData.find(x => context.content.config.MixAllup6LegPools.includes(x.betType)) || context.content.config.MixAllup6LegPools.includes(data.betType);
        let isAllPoolSame = fbALlupData.filter(x => fbALlupData.filter(k => k.betType != x.betType).length != 0).length == 0 && fbALlupData[0]?.betType===data.betType;
        let resetFormula = false;

        if (isMatchExists) {
            // allupData.alertMsg = t('LB_FB_CAL_SELECTED_ALERT');
            context.state.toastApi({ message:  t('LB_FB_CAL_SELECTED_ALERT'), state: 'warning'});
        } else if (fbALlupData.length >= 8) {
            const alertMsg = (isAllPoolSame ? t('LB_FB_CAL_MAX_8_LEG_ALERT_SAME').replace('{0}', t('LB_FB_TITLE_' + fbALlupData[0].betType)) : t('LB_FB_CAL_MAX_8_LEG_ALERT'));

            context.state.toastApi({ message:  alertMsg, state: 'warning' });
        } else if (hasMixAllup6LegPools && fbALlupData.length >= 6) {
            const alertMsg = (isAllPoolSame ? t('LB_FB_CAL_MAX_6_LEG_ALERT_SAME').replace('{0}', t('LB_FB_TITLE_' + fbALlupData[0].betType)) : t('LB_FB_CAL_MAX_6_LEG_ALERT'));
            context.state.toastApi({ message:  alertMsg, state: 'warning'});
        } else {
            addAllUpCalEntry(data);
            allupData.alertMsg = '';
            resetFormula = true;
            context.state.toastApi({ message:  t('LB_ALL_UP_ADDED')});
        }

        // allupData.isExpand = true;
        if ( resetFormula ) {
            updateAllUpData();
        }
        setAllupData({...allupData});
    }, [context.content.allupCalData]);

    useEffect(() => {
        allupData.alertMsg = '';
        setAllupData({...allupData});
    } , [updateLayout])

    const updateAllUpData = () => {
        let sels = getAllUpCalEntries();

        let alupSize = sels.length;
        if (alupSize > 1 ) {
            allupData.alupCnt = alupSize;
            allupData.alupFormula = context.content.config.AllupFormula[alupSize][0];
            calculate();
        }
        else if (alupSize === 1) {
            allupData.alupCnt = 1;
            allupData.alupFormula = '';
            calculate();
        }
        else {
            allupData.alupCnt = 0;
            allupData.alupFormula = '';
            allupData.noOfComb = '-';
            allupData.dividend = '-';
            allupData.netReturn = '-';
        }
    };

    const handleClick = () => {
        allupData.isExpand = !allupData.isExpand;
        setAllupData({...allupData});
    }

    const handleSelectFormula = (item) => {
        allupData.alupFormula = item;
        calculate();
        setAllupData({...allupData});
    }

    const handleBetUnitChange = (event) => {
        const tranValue = event.target.value.replace(/\,/g, '')
        if (tranValue!='' && tranValue >= 0) {
            let unitBet =  parseInt(tranValue).toString() === 'NaN' ? 0 : parseInt(tranValue)
            sessionStorage.setItem('__extendDivCalUnitBet', unitBet);
            allupData.unitBet = unitBet;
        }
        else {
            allupData.unitBet = -1;
        }
        calculate();
        setAllupData({...allupData});
    }

    const quickUnitBetChange = (amt) => {
        let newAmt = allupData.unitBet + amt;
        if ( newAmt < 10 ) {
            newAmt = 10;
        }
        else if ( newAmt > parseInt(GetChannelPara('MaxBetUnit')) ) {
            newAmt = parseInt(GetChannelPara('MaxBetUnit'));
        }

        sessionStorage.setItem('__extendDivCalUnitBet', newAmt);
        allupData.unitBet = newAmt;
        calculate();
        setAllupData({...allupData});
    }

    const checkBetUnit = (event) => {
        if (event.target.value < 10) {
            alert(t('LB_FB_AMOUNTERROR'))
            allupData.unitBet = 10;
            sessionStorage.setItem('__extendDivCalUnitBet', 10);
            calculate();
            setAllupData({...allupData});
        }
    }

    const resetAllupCal = () => {
        allupData.unitBet = parseInt(getPersonalSettings('football', 'ALUP'), 10),
        allupData.noOfComb = '-';
        allupData.dividend = '-';
        allupData.netReturn = '-';
        allupData.alupCnt = 0;
        allupData.alupFormula = '';
        allupData.alertMsg = '';
        setAllUpCalEntries([]);
        setAllupData({...allupData});
    }

    const deleteAllupItem = (id) => {
        removeAllUpCalEntry(id);
        updateAllUpData();
        setAllupData({...allupData});
        triggerUpdate({...[]});
    }

    const updateResult = (idx, res) => {
        let data = getAllUpCalEntries();
        data[idx].result = res;
        setAllUpCalEntries(data);
        calculate();
        setAllupData({...allupData});
    }


    function hasLinePools() {
        let dataArr = getAllUpCalEntries();
        return dataArr.filter(x => context.content.config.GoalLinePools.includes(x.betType)).length > 0;
    }

    const calculate = () => {
        var totalSel = 0;
        var totalDiv = 0;
        let dataArr = getAllUpCalEntries();
        let combArr = [];

        if ( dataArr.length===0 ) {
            return;
        }

        if (dataArr.length > 1) {
            var tmp = alupFormulaItem[allupData.alupFormula];
            combArr = tmp.split('#');	//if 3x7, 1#2#3#12#13#23#123
            totalSel = combArr.length;
        } else if (dataArr.length == 1) {
            totalSel = 1;
            combArr = ['1'];
        }

        for (var i = 0; i < combArr.length; i++) {
            var tmpDiv = 1;
            for (var j = 0; j < combArr[i].length; j++) {
                var idx = parseInt(combArr[i].charAt(j)) - 1;
                var factor = 0;
                if (dataArr[idx].condition?.indexOf('/') >= 0) {
                    var tmpLine = dataArr[idx].result.split('/');
                    for (var k = 0; k < tmpLine.length; k++) {
                        if (tmpLine[k] === 'W')
                            factor += parseFloat(dataArr[idx].oddsVal) / tmpLine.length;
                        else
                            factor += parseFloat(tmpLine[k]) / tmpLine.length;
                    }
                }
                else {
                    if (dataArr[idx].result === 'W')
                        factor = parseFloat(dataArr[idx].oddsVal);
                    else
                        factor = parseFloat(dataArr[idx].result);
                }
                tmpDiv *= factor;
            }
            totalDiv += tmpDiv;
        }

        totalDiv *= allupData.unitBet;
        let netRtn = Math.round(totalDiv * 10) / 10 - allupData.unitBet * totalSel;

        allupData.noOfComb = totalSel;
        allupData.dividend = totalDiv;
        allupData.netReturn = netRtn;
    }

    const CalculatorHeader = () => {
        return <>
            <a className='left' onClick={handleClick}>
                <div id='allup-cal-btnExpand' className='couponBtn'>
                    <ArrowIcon className='hueRotate' direction='down' change={allupData.isExpand} changeDirection='up' size={20} />
                    </div>
                <span>{t('LB_ALLUP_CALCULATOR')}</span>
            </a>
            <div style={{ display: "flex", gap: '8px' }} className='right'>
                <WebTVIcon />
                <AddSlipBtn />
            </div>
        </>
    }

    const CalculatorHeaderMobile = useCallback(({handleClick, allupData}) => {
        let fbALlupData = getAllUpCalEntries();
        return <>
            <a className='left' >
                <img src={calculateSvg} />
                <span>{t('LB_ALLUP_CALCULATOR')}</span>


            </a>
            <div className='right' onClick={handleClick}>
                <ArrowIcon id='allup-cal-btnExpand' className='' direction='down' change={allupData.isExpand} changeDirection='up' size={30} />
            </div>
        </>
    },[])



    const disabledBtn = getAllUpCalEntries().length === 0

    const tableProps = {hasLinePools, deleteAllupItem, context, updateResult, initTimeRef}

    const allupCalRef = useRef();
    useIntersectionObserver(allupCalRef.current, props?.setAllupCalIntersectionRatio)

    return props.enable && !isMobile && <>
        <div ref={allupCalRef} className={`allup-cal ${allupData.isExpand ? 'allup-cal-expend': ''}`}>
            {!isMobile && <CalculatorHeader />}

            {isMobile && <CalculatorHeaderMobile handleClick={handleClick} allupData={allupData}/>}
        </div>
        {allupData.isExpand && <>
            {<div className={`allup-cal-table`}>
                {
                    isMobile ? <div className='data-table-mobile'><DataTableMobile {...tableProps}/></div> : <DataTable {...tableProps}/>
                }
            </div>}

            {<div className={`allup-cal-content`}>
                <AllupDetailCalPanel isAllupCal={true} allupData={allupData} handleBetUnitChange={handleBetUnitChange}
                            checkBetUnit={checkBetUnit} quickUnitBetChange={quickUnitBetChange}
                            handleSelectFormula={handleSelectFormula} forceAlertMsg={allupData.alertMsg} >
                    <div className={`btnReset cta_fb ${disabledBtn ? 'disabled' :""}`} onClick={()=> !disabledBtn && resetAllupCal()}>{t('LB_FB_RESET')}</div>
                    <AddFBAllupCalcSlipBtn allupAddSlipObj={{
                        selectedFormula: allupData.alupFormula,
                        betUnit: allupData.unitBet,
                        noOfComb: allupData.noOfComb
                    }}
                    disabled={disabledBtn}/>
                </AllupDetailCalPanel>
            </div>}
        </>}
    </>
}
export default AllupCalculator;

const Result = ({ index, data, updateResult, context }) => {

    const { t, i18n } = useTranslation()
    let isHandicapPool = context.content.config.HandicapOnlyPools.includes(data.betType);
    let isGoallinePool = context.content.config.GoalLinePools.includes(data.betType);
    let isMobileSize = context.state.windowSize.isMobileSize

    const TickCorssBox = useCallback(({data, updateResult, index}) => {
        let isChecked = data.result === 'W';
        return <div className={`allupCal-result ${!isMobileSize ? 'right-border' : ''}`}>
            <div className={`icon-cross-box ${isChecked ? 'dim' : 'show'}`} onClick={() => updateResult(index, 0)} >
                <div className='icon-cross' />
            </div>
            <div className={`icon-tick-box ${isChecked ? 'show' : 'dim'}`} onClick={() => updateResult(index, "W")} >
                <div className='icon-tick' />
            </div>
        </div>
    },[])

    const DropdownBox = useCallback(({updateResult, isGoallinePool, isHandicapPool, data, t, index}) => {
        let itemList = [];
        if (isGoallinePool) {
            itemList = getGoallineResultItems(data, t);
        } else if (isHandicapPool) {
            itemList = getHandicapResultItems(data, t);
        }

        let selectedItem = itemList.filter(x=> x.res===data.result)[0];

        return <div className={`${!isMobileSize ? 'right-border-before' : ''}`}>
            <DropdownButton
                as={ButtonGroup}
                key={`ddAllupResult-${index}`}
                id={`ddAllupResult-${index}`}
                align="end"
                variant={selectedItem.value}
                title={<>{selectedItem.value}<ArrowIcon direction='down' size={24}/></>}
            >
                {itemList.map((item, idx) => {
                        return <Dropdown.Item
                            eventKey={'result-value-' + idx}
                            key={`result-value-` + idx}
                            disabled={false}
                            align='start'
                            variant={item.value}
                            title={item.value}
                            value={item.value}
                            onClick={() => updateResult(index, item.res)}>
                            {item.value}
                        </Dropdown.Item>
                    })
                }
            </DropdownButton>
        </div>
    }, [])

    const resProps ={
        updateResult, isGoallinePool, isHandicapPool, data, t, index
    }


    let showDD = isHandicapPool || isGoallinePool;
    return showDD ? <DropdownBox {...resProps} /> : <TickCorssBox {...resProps}/>
}



const AllupCalSelection = ({ item, context }) => {
    const { t, i18n } = useTranslation()

    if ( context.content.config.CorrectScorePools.includes(item.betType) ) {
        if(isCRSOtherSels(item.str)) return t('LB_FB_'+item.str).replace('{nl}','\n')
        return item["name_" + i18n.language];
    } else if ( context.content.config.FirstScorerPools.includes(item.betType) ) {
        return item.str + " " + item["name_" + i18n.language];
    } else if (["HFT"].includes(item.betType)) {
        let [ h, a ] = item.str.split(":");
        return t('LB_FB_CAL_' + h) + "-" + t('LB_FB_CAL_' + a)
    } else if (["TTG", "ETG"].includes(item.betType)) {
        return item.str
    } else {
        return t('LB_FB_CAL_' + item["name_en"].toUpperCase())
    }
}

const DataTable = ({hasLinePools, deleteAllupItem, context, updateResult, initTimeRef}) => {
    const { t, i18n } = useTranslation()
    let hasLine = hasLinePools();
    let fbALlupData = getAllUpCalEntries();

    return <>
        {
            fbALlupData.length > 0 ? <><div className={`header ${hasLine ? 'hasLine' : ''}`}>
                <div className='type'>{t('LB_FB_TYPE')}</div>
                <div>{t('LB_FB_EVENT_ID')}</div>
                <div className='team'>{t('LB_FB_TEAM')}<br />({t('LB_FB_HOME_VS_AWAY')})</div>
                <div className={`${hasLine ? '' : 'hide'}`}>{t('LB_FB_LINE')}</div>
                <div>{t('LB_FB_SELECTION')}</div>
                <div>{t('LB_ODDS')}</div>
                <div>{t('LB_FB_RESULTS')}</div>
                <div>{t('LB_FB_DELETE')}</div>
            </div>
                <div className='content pc'>
                    {
                        fbALlupData.map((v, idx) => {
                            let goalStr = "";
                            let hideOddsIndicator = !v.oddUpdTs || initTimeRef.current.diff(v.oddUpdTs, 'second') >= 0
                            let oddsChangeCss = getOddsArrowCss({ oddsDiff: v.oddsDiff, oddUpdTs: v.oddUpdTs }, hideOddsIndicator)
                            if (context.content.config.NTSPools.includes(v.betType)) {
                                let ngoal = `${v.instNo}${i18n.language=="en" ? getNumberSuffix(v.instNo) : ''}`;
                                goalStr = ` (${t('LB_FB_N_GOAL').replace('{0}', ngoal)})`;
                            }
                            return <div className={`cal-row ${hasLine ? 'hasLine' : ''}`} key={v.frontEndId}>
                                <div className='type right-border'><span><Trans
                                    defaults={t('LB_FB_TITLE_' + v.betType).replace(/(\/)/g,'$1<wbr />') + goalStr}
                                    components={{ wbr: <wbr /> }}
                                    i18nKey="LB_FB_TITLE_BETTYPE"
                                /></span></div>
                                <div className='right-border'>{v.frontEndId}</div>
                                <div className='team right-border'>
                                    {v['homeTeam_' + i18n.language] + " "}{v.hG} {t('LB_FB_VS')} {v['awayTeam_' + i18n.language]}{v.aG}
                                </div>
                                <div className={`right-border ${hasLine ? '' : 'hide'}`}>{!context.content.config.GoalLinePools.includes(v.betType) || v.lineNum == null ? '-' : '[' + v.lineNum + ']'}</div>
                                <div className='selection right-border'>
                                    <AllupCalSelection item={v} context={context}/>
                                </div>
                                <div className={`odds right-border ${oddsChangeCss}`}>
                                    <div>{v.oddsVal}</div>
                                    <div className={`oddsArrow ${oddsChangeCss}`}></div>
                                    </div>
                                <Result index={idx} data={v} context={context} updateResult={updateResult}/>
                                <div><div className='icon-delete' onClick={() => deleteAllupItem(idx)} /></div>
                            </div>

                        })
                    }
                </div>
            </> : null
        }
    </>

}
const DataTableMobile = ({hasLinePools, deleteAllupItem, context, updateResult, initTimeRef}) => {
    const { t, i18n } = useTranslation()

    let hasLine = hasLinePools();
    let fbALlupData = getAllUpCalEntries();

    let rows = fbALlupData.map((v, idx) => {
        let goalStr = "";
        let hideOddsIndicator = !v.oddUpdTs || initTimeRef.current.diff(v.oddUpdTs, 'second') >= 0
        let oddsChangeCss = getOddsArrowCss({ oddsDiff: v.oddsDiff, oddUpdTs: v.oddUpdTs }, hideOddsIndicator)
        if (context.content.config.NTSPools.includes(v.betType)) {
            let ngoal = `${v.instNo}${i18n.language=="en" ? getNumberSuffix(v.instNo) : ''}`;
            goalStr = ` (${t('LB_FB_N_GOAL').replace('{0}', ngoal)})`;
        }
        return <EqualHeight>
            <div>
                <div className={`header ${hasLine ? 'hasLine' : ''}`}>
                    <div className='type'>{t('LB_FB_TYPE')}</div>
                    <div>{t('LB_FB_EVENT_ID')}</div>
                    <EqualHeightElement name="term">{t('LB_FB_TEAM')}</EqualHeightElement>
                    <div className={`${hasLine ? '' : 'hide'}`}>{t('LB_FB_LINE')}</div>
                    <div>{t('LB_FB_SELECTION')}</div>
                    <div>{t('LB_ODDS')}</div>
                    <div>{t('LB_FB_RESULTS')}</div>
                </div>
                <div className={`cal-row ${hasLine ? 'hasLine' : ''}`}>
                    <div className='type'>{t('LB_FB_TITLE_' + v.betType)}{goalStr}</div>
                    <div>{v.frontEndId}</div>
                    <EqualHeightElement className='team'  name="term">
                        {v['homeTeam_' + i18n.language] + " "}{v.hG} {t('LB_FB_VS')} {v['awayTeam_' + i18n.language]}{v.aG}
                    </EqualHeightElement>
                    <div className={`${hasLine ? '' : 'hide'}`}>{!context.content.config.GoalLinePools.includes(v.betType) || v.lineNum == null ? '-' : '[' + v.lineNum + ']'}</div>
                    <div className='selection'>
                        <AllupCalSelection item={v} context={context}/>
                    </div>
                    <div className={`odds ${oddsChangeCss}`}>
                        {v.oddsVal}
                        <div className={`oddsArrow ${oddsChangeCss}`}></div>
                        </div>
                    <Result index={idx} data={v} context={context} updateResult={updateResult}/>

                </div>
                <div><div className='icon-delete' onClick={() => deleteAllupItem(idx)} /></div>
            </div>
        </EqualHeight>
    })

    return <>
        {rows.length > 0 && <div className='content'>
            {rows}
        </div>}
    </>

}