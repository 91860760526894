import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isLocalMeeting } from '../../Common/home-common';
import { lblBr } from '../../Common/GlobalFunc';
import { RacingContext } from '../../Home/RacingPage';
import { getPwinTimes } from './RCOddsTableBase';

const RCOddsHeaderM = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);

    const isLocal = () => {
        return isLocalMeeting(context.content.venue);
    }

    const [pTime0, pTime1, winTime] = getPwinTimes(context);

    let cssClass = "rc-odds-table-header-m";
    if (props.raceStaticHeader != null) {
        cssClass = "rc-odds-table-header-m evenbg cwin-table-container";
    }

    return (
        <tr className={cssClass}>
            {props.columns.raceNo ? <td className="no">{t('LB_RC_ODDS_TABLE_RACENO')}</td> : null}
            {props.columns.no ? <td className="no">{t('LB_RC_ODDS_TABLE_NO')}</td> : null}
            {props.columns.banker1 ? <td className="leg">{props.lbl.banker1}</td> : null}
            {props.columns.banker2 ? <td className="leg">{props.lbl.banker2}</td> : null}
            {props.columns.banker3 ? <td className="leg">{props.lbl.banker3}</td> : null}
            {props.columns.leg ? <td className="leg">{props.lbl.leg}</td> : null}
            {props.columns.horseName ? <td className="horseName">{t('LB_RC_ODDS_TABLE_HRNAME')}</td> : null}
            {props.columns.rcNoteM && isLocal() ? <td className="RCNOTE">{lblBr(t('LB_RC_ODDS_TABLE_RCNOTE_BR'))}</td> : null}
            {props.columns.pwin0 ? <td className="pwinCol pwinCol-m" colSpan={3}>
                <div className="pwinheader">{t('LB_RACE_PWIN')}</div>
                <div className='pwinColContainer'>
                    <div>{pTime0}</div>
                    {props.columns.pwin1 ? <div >{pTime1}</div> : null}
                    <div className="win">{winTime}</div>
                </div>
                </td> :null }
            {props.columns.win && !props.columns.pwin0 ? <td className=" win">{props.raceStaticHeader ? t('LB_ODDS'): t('LB_RC_ODDS_TABLE_WIN')}</td> : null}
            {props.columns.place ? <td className="place">{t('LB_RC_ODDS_TABLE_Place')}</td> : null}
            {props.columns.wp ? <td className="wp">{t('LB_RC_ODDS_TABLE_WINPlace')}</td> : null}
            {props.columns.draw && props.columns.standby ? (!props.columns.showStandby ? <td className="draw">{t('LB_RC_ODDS_TABLE_DRAW')}</td> : <td></td>) : null}
            {props.columns.wt && props.columns.standby ? <td className="wt">{t('LB_RC_ODDS_TABLE_WT')}</td> : null}
            <td className="any"></td>
        </tr>
    )
}
export default RCOddsHeaderM;