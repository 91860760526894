import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
    formulaHeader,
    chanceHeader,
    formulaText,
    unitbetrange,
    // ftBetTypeText,
    ftcLangFn,
    formulainfo,
    setFtCalcBetVaue,
    allupchance
} from '../Common/FTCalcBetcal';
import { NewWindow } from '../../Common/home-common';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import CloseButton from '../../Common/ui-components/CloseButton';
import { useWindowSize } from '../../Common/CommonHooks';
import { IsBetTypeEnabled } from '../../Common/ChannelParaFunc';

let betTypeText = [
    [
        'HAD (主客和)',
        'FHAD (半場主客和)',
        'HHAD (讓球主客和)',
        'HDC (讓球)',
        'FHDC (半場讓球)',
        'HILO (入球大細)',
        'FHL (半場入球大細)',
        'CHLO (開出角球大細)',
        'FCHLO (半場開出角球大細)',
        'CHDC (開出角球讓球)',
        'FCHDC (半場開出角球讓球)',
        'CRS (波膽)',
        'FCS (半場波膽)',
        'FTS (第一隊入球)',
        'TTG (總入球)',
        'OOE (入球單雙)',
        'FGS (首名入球)',
        'HFT (半全場)'
    ],
    [
        'HAD (Home/Away/Draw)',
        'FHAD (First Half HAD)',
        'HHAD (Handicap HAD)',
        'HDC (Handicap)',
        'FHDC (First Half Handicap)',
        'HILO (HiLo)',
        'FHL (First Half HiLo)',
        'CHLO (Corner Taken HiLo)',
        'FCHLO (First Half Corner Taken HiLo)',
        'CHDC (Corner Taken Handicap)',
        'FCHDC (First Half Corner Taken Handicap)',
        'CRS (Correct Score)',
        'FCS (First Half Correct Score)',
        'FTS (First Team to Score)',
        'TTG (Total Goals)',
        'OOE (Odds Even)',
        'FGS (First Scorer)',
        'HFT (HaFu)'
    ]
];

const formatBetType = (item) => {
    let formatBetType=item.split(' ')?.[0];
        if(formatBetType=='FHDC'){
            item='FHH'
        } else if(formatBetType=='FCHDC'){
            item='FHC'
        }
    return item;
}

const filterBetTypeText = () => {
    let ftBetTypeText = [];
    ftBetTypeText[0] = betTypeText[0].filter((item) => {
        return IsBetTypeEnabled(formatBetType(item).substring(0, 3))
    });
    ftBetTypeText[1] = betTypeText[1].filter((item) => {
        return IsBetTypeEnabled(formatBetType(item).substring(0, 3))
    });
    if(!ftBetTypeText[0]?.length){
        ftBetTypeText = betTypeText // No data returned from the backend
    }
    return ftBetTypeText;
};

const FTCalculator = () => {
    const ftBetTypeText = filterBetTypeText();

    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const langIndex = lang === 'en' ? 1 : 0;
    const navigate = useNavigate();

    const [curBetType, setCurBetType] = useState('');
    const [curAllupFormula, setCurAllupFormula] = useState('');
    const [curUnitBet, setCurUnitBet] = useState(null);
    const [curLegChance, setCurLegChance] = useState(['', '']);
    const [curBetChance, setCurBetChance] = useState([0, 1, 0, 0, 0, 0, 0, 0]);
    const [curLegSelect, setCurLegSelect] = useState([1, 1]);
    const [selection, setSselection] = useState([1, 2, 3]);
    const [curFormulaText, setCurFormulaText] = useState([]);
    const { isMobileSize } = useWindowSize();

    useEffect(() => {
        if (ftBetTypeText[langIndex][0]) {
            setCurBetType(ftBetTypeText[langIndex][0]);
        } else {
            setCurBetType(false);
        }

        ftcLangFn(langIndex);
    }, []);

    useEffect(() => {
        if(!curBetType) return
        let type = curBetType.split(' ')[0];
        let selNum = 3;
        let max6Leg = false;
        if (['HAD', 'FHAD', 'HHAD', 'FTS'].includes(type)) {
            selNum = 3;
        }
        if (['HDC', 'HILO', 'FHL', 'CHLO', 'FCHLO', 'OOE'].includes(type)) {
            selNum = 2;
        }
        if (['CRS', 'FCS'].includes(type)) {
            selNum = 33;
            max6Leg = true;
        }
        if (['TTG'].includes(type)) {
            selNum = 8;
        }
        if (['FGS'].includes(type)) {
            selNum = 41;
        }
        if (['HFT'].includes(type)) {
            selNum = 9;
            max6Leg = true;
        }
        if (max6Leg) {
            setCurFormulaText(formulaText[langIndex].slice(0, 41));
        } else {
            setCurFormulaText(formulaText[langIndex]);
        }
        setSselection(Array.from(new Array(selNum + 1).keys()).slice(1));
        setCurAllupFormula(formulaText[langIndex][0]);
        setCurUnitBet(unitbetrange[0]);
        setCurLegSelect([1, 1]);
        setFtCalcBetVaue([0, 1, 1], 'legchance');
        setCurBetChance([0, 1, 0, 0, 0, 0, 0, 0]);
    }, [curBetType]);

    useEffect(() => {
        let length = curAllupFormula.slice(0, 1) - 0;
        setFtCalcBetVaue(curAllupFormula, 'selectedFormula');
        if (length === 0) {
            return;
        }
        const arr = [1, 1, 1, 1, 1, 1, 1, 1];
        if (curLegChance.length !== length) {
            const newArr = arr.slice(0, length);
            setCurLegChance(newArr);
            let newSelArr = [];
            if (length > curLegChance.length) {
                const addNum = length - curLegChance.length;
                const addArr = arr.slice(0, addNum);
                newSelArr = [...curLegSelect, ...addArr];
            } else {
                newSelArr = [...curLegSelect.slice(0, length)];
            }
            setCurLegSelect(newSelArr);
            setFtCalcBetVaue([0, ...newSelArr], 'legchance');
            if (document.querySelector('.ftCalTable')) {
                document.querySelector(
                    '.ftCalTable'
                ).style.gridTemplateColumns = `112px repeat(${newSelArr.length}, 88px)`;
            }
        }
        ftcLangFn(langIndex);
        calcAllupBetFn();
    }, [curAllupFormula, isMobileSize]);

    const calcAllupBetFn = () => {
        setFtCalcBetVaue('', 'allupchanceFn');
        setCurBetChance(allupchance.slice(1));
    };

    const handleChangeSelect = (value, selectType, index) => {
        if (selectType === 'betType') {
            setCurBetType(value);
        } else if (selectType === 'formula') {
            setCurAllupFormula(value);
        } else if (selectType === 'unitBet') {
            setCurUnitBet(value);
        } else if (selectType === 'legSelection') {
            let newlegSelect = [...curLegSelect];
            newlegSelect[index] = value;
            setCurLegSelect(newlegSelect);
            setFtCalcBetVaue([0, ...newlegSelect], 'legchance');
            calcAllupBetFn();
        }
    };

    const getChance = () => {
        let num = 0;
        curBetChance.map((item) => {
            num = num + item;
        });
        return num;
    };

    const getTotalAmount = () => {
        let num = 10;
        num = getChance() * curUnitBet;
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    const handleClickBack = () => {
        navigate(-1);
    };

    const formatBetTypeLabel = (text) => {
        if (!text) {
            return '';
        }
        let newText = text;
        if (window.innerWidth < 769) {
            newText = newText.split('(')[1].split(')')[0];
        }
        return newText;
    };

    const goToFBComb = () => {
        const url = `/${lang}/football/bet_six`;
        const w = isMobileSize ? null : 828;
        const h = isMobileSize ? null : 561;
        NewWindow(url, 'Int_All Up Combinations', w, h);
    };

    const HeaderRightUI = () => {
        return (
            <div className="dfFe">
                <div className="allUpComb" onClick={goToFBComb}>
                    {t('LB_FB_TITLE_ALL_UP_COMBINATIONS')}
                </div>
                <div className="ftCalcBtn" onClick={goToFBComb}>
                    <div className="ftCalcIcon"></div>
                </div>
            </div>
        );
    };

    return (
        curBetType && (
            <div className="FTCalculator">
                <div className="ftCalcContainer">
                    <div className={`ftCalcTitle ${isMobileSize?'popupTitle':''}`}>
                        <div>{t('LB_FB_INVESTMENT_CALCULATOR')}</div>
                        {!isMobileSize && <HeaderRightUI />}
                        {isMobileSize && <CloseButton isIOSPWA={true} />}
                    </div>
                    {isMobileSize && <HeaderRightUI />}
                    <div className="ftCalcBetComb">
                        <div className="dfr">
                            <DropdownButton
                                title={
                                    <div className="dfr">
                                        <span>{t('LB_BET_TYPE')} : </span>
                                        <div className="dfc">{formatBetTypeLabel(curBetType)}</div>
                                        <ArrowIcon direction="down" size={24} />
                                    </div>
                                }
                                align="end"
                                id="ftBetTypeText"
                                value={curBetType}
                            >
                                {ftBetTypeText[langIndex].map((item, index) => {
                                    return (
                                        <Dropdown.Item
                                            value={item}
                                            onClick={() => handleChangeSelect(item, 'betType')}
                                            active={curBetType === item}
                                            key={item}
                                        >
                                            {formatBetTypeLabel(item)}
                                        </Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                        <div className="dfr">
                            <DropdownButton
                                title={
                                    <>
                                        <div className="dfr">
                                            <span>{t('LB_FB_ALL_UP_FORMULA')} : </span>
                                            <div className="dfc">{curAllupFormula}</div>
                                            <ArrowIcon direction="down" size={24} />
                                        </div>
                                    </>
                                }
                                align="end"
                                id="formulaText"
                                value={curAllupFormula}
                            >
                                {curFormulaText.map((item, index) => {
                                    return (
                                        <Dropdown.Item
                                            value={item}
                                            onClick={() => handleChangeSelect(item, 'formula', index)}
                                            active={curAllupFormula === item}
                                            key={item}
                                        >
                                            {item}
                                        </Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                        <div className="dfr">
                            <DropdownButton
                                title={
                                    <>
                                        <div className="dfr">
                                            <span>{t('LB_RC_UNIT_BET')} : $ </span>
                                            <div className="dfc">{curUnitBet}</div>
                                            <ArrowIcon direction="down" size={24} />
                                        </div>
                                    </>
                                }
                                align="end"
                                id="unitbetrange"
                                value={curUnitBet}
                            >
                                {unitbetrange.map((item, index) => {
                                    return (
                                        <Dropdown.Item
                                            value={item}
                                            onClick={() => handleChangeSelect(item, 'unitBet')}
                                            active={curUnitBet === item}
                                            key={item}
                                        >
                                            {item}
                                        </Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>
                    <div className="ftCalcSelection">
                        {isMobileSize ? (
                            <>
                                <div className="ftCalMTable">
                                    <div className=" ftCalMTableHeader bgColor">
                                        <div className="ftCalNum"></div>
                                        <div className="ftCalNumSelect">{t('LB_FB_SELECTIONS')}</div>
                                        <div className="ftCalFormula"></div>
                                        <div className="ftCalBetNo">{t('LB_RC_NO_OF_BETS')}</div>
                                    </div>
                                    {curLegChance?.map((item, index) => {
                                        return (
                                            <div
                                                className={`ftCalMTableRow ${index % 2 ? 'bgColor' : ''} ${
                                                    index == 7 ? 'lastDDSelect' : ''
                                                }`}
                                                key={new Date().getTime() + index}
                                            >
                                                <div className="ftCalNum">{formulaHeader[langIndex][index]}</div>
                                                <div className="ftCalNumSelect">
                                                    <DropdownButton
                                                        title={
                                                            <>
                                                                {curLegSelect[index]}
                                                                <ArrowIcon direction="down" size={24} />
                                                            </>
                                                        }
                                                        align="start"
                                                        id="legSelection"
                                                        drop={index == 7 ? 'up' : 'down'}
                                                        value={curLegSelect[index]}
                                                    >
                                                        {selection.map((item, indexs) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    value={item}
                                                                    onClick={() =>
                                                                        handleChangeSelect(item, 'legSelection', index)
                                                                    }
                                                                    active={curLegSelect[index] === item}
                                                                    key={item}
                                                                >
                                                                    {item}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                    </DropdownButton>
                                                </div>
                                                <div className="ftCalFormula">{chanceHeader[langIndex][index]}</div>
                                                <div className="ftCalBetNo">{curBetChance[index]}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="ftCalTable" id="ftCalTable">
                                    <div className=" ftCalColFirst">
                                        <div className="formulaRow bgColor"></div>
                                        <div className="betSelectRow dfc ">{t('LB_FB_SELECTIONS')}</div>
                                        <div className="formulaRow bgColor"></div>
                                        <div className="betSelectRow1 dfc ">{t('LB_RC_NO_OF_BETS')}</div>
                                    </div>
                                    {curLegChance.map((item, index) => {
                                        return (
                                            <div className="ftCalCol" key={new Date().getTime() + index}>
                                                <div className="formulaRow dfcc bgColor">
                                                    {formulaHeader[langIndex][index]}
                                                </div>
                                                <div className="betSelectRow dfcc ">
                                                    <DropdownButton
                                                        title={
                                                            <>
                                                                {curLegSelect[index]}
                                                                <ArrowIcon direction="down" size={24} />
                                                            </>
                                                        }
                                                        align="start"
                                                        id="legSelection"
                                                        value={curLegSelect[index]}
                                                    >
                                                        {selection.map((item, indexs) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    value={item}
                                                                    onClick={() =>
                                                                        handleChangeSelect(item, 'legSelection', index)
                                                                    }
                                                                    active={curLegSelect[index] === item}
                                                                    key={item}
                                                                >
                                                                    {item}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                    </DropdownButton>
                                                </div>
                                                <div className="formulaRow dfcc bgColor">
                                                    {chanceHeader[langIndex][index]}
                                                </div>
                                                <div className="betSelectRow1 dfcc ">{curBetChance[index]}</div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className=" ftCalColBG">
                                    <div className="formulaRow bgColor"></div>
                                    <div className="betSelectRow  "></div>
                                    <div className="formulaRow bgColor"></div>
                                    <div className="betSelectRow1  "></div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="ftCalcBotArea">
                        <div className="dfc">
                            <div>{t('LB_BS_TOTAL_NO_OF_BETS')}</div>
                            <div className="text">{getChance()}</div>
                        </div>
                        <div className="dfc">
                            <div>{t('LB_BS_Total_AMOUNT')}</div>
                            <div className="text">${getTotalAmount()}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default FTCalculator;
