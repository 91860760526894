import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GetRaceConfig } from '../../Common/ConfigHelper';
import { RacingContext } from '../../Home/RacingPage';
import { isStartSell } from './Common';

const PoolInvTable = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);

    function getMergedPoolLbl(memPools, pools) {
        let outStr = '';
        let outPools = [];
        for (let idx in pools) {
            if (memPools.indexOf(pools[idx]) >= 0)
                outPools.push(pools[idx]);
        }
        switch (outPools.length) {
            case 3:
                outStr = t('LB_RC_POOLINV_MERGED_3_POOLS')
                    .replace('{0}', t('LB_' + outPools[0]))
                    .replace('{1}', t('LB_' + outPools[1]))
                    .replace('{2}', t('LB_' + outPools[2]));
                break;
            case 2:
                outStr = t('LB_RC_POOLINV_MERGED_2_POOLS')
                    .replace('{0}', t('LB_' + outPools[0]))
                    .replace('{1}', t('LB_' + outPools[1]));
                break;
            case 1:
                outStr = t('LB_' + outPools[0]);
                break;
            default:
                break;
        }
        return outStr;
    }

    function getMemberPools(invList, pid) {
        let pools = [];
        for ( let idx in invList ) {
            if ( invList[idx].mergedPoolId==pid ) {
                pools.push(invList[idx].oddsType);
            }
        }
        return pools;
    }

    const mtg = context.content.meeting;

    let output = null;
    if (mtg==null || mtg.poolInvs==null || !isStartSell(mtg)) {
        output = <div></div>;
    } else {
        let config = GetRaceConfig();
        let singleRacePools = config.singleRacePool.split(';');
        let multiRacePools = config.multiRacePool.split(';');
        let singleTotal = 0;
        let allTotal = 0;
        let singleOutput = [];
        let multiOutput = [];
        let isComingleRace = mtg.obSt.filter(data => data.leg.races[0]==context.content.raceNo && (data.comingleStatus == "1" || data.comingleStatus == "2")).length > 0;
        let anyComingleRace = mtg.obSt.filter(data => data.comingleStatus == "1" || data.comingleStatus == "2").length > 0;
        let showGrandTotal = !anyComingleRace && context.content.raceNo == Math.max(...mtg.poolInvs.map(x=>x.leg.races[0]));
        let showWinMergeRemarks = false;
        let winMergeRemarkTxt = '';
        let appendPools = [];
        let i = 0;
        for (let idx=0; idx<singleRacePools.length; idx++) {
            let pData = mtg.poolInvs.filter(data=> data.leg.races[0]==context.content.raceNo && data.oddsType==singleRacePools[idx])[0];
            if (pData != null) {
                if (pData.mergedPoolId != null) {
                    if (appendPools.indexOf(singleRacePools[idx]) >= 0)
                        continue;

                    let memPools = getMemberPools(mtg.poolInvs, pData.mergedPoolId);
                    switch (singleRacePools[idx]) {
                        case "WIN":
                        case "IWN":
                        case "CWA":
                        case "CWB":
                        case "CWC":
                            singleOutput.push(<tr id={`poolInv${singleRacePools[idx]}`} className={`rc-inv-row ${i % 2 == 0 ? '' : 'sec'}`}><td>{t('LB_' + singleRacePools[idx])}*</td><td>$ {parseInt(pData.investment || 0).toLocaleString()}</td></tr>);
                            showWinMergeRemarks = true;
                            let displayIwnRemarks = memPools.indexOf("IWN")>=0;
                            let displayCwinRemarks = memPools.indexOf("CWA")>=0 || memPools.indexOf("CWB")>=0 || memPools.indexOf("CWC")>=0;
                            if (displayIwnRemarks && displayCwinRemarks)
                                winMergeRemarkTxt = t('LB_RC_POOLINV_CWIN_IWN_REMARKS');
                            else if (displayIwnRemarks)
                                winMergeRemarkTxt = t('LB_RC_POOLINV_IWN_REMARKS');
                            else if (displayCwinRemarks)
                                winMergeRemarkTxt = t('LB_RC_POOLINV_CWIN_REMARKS');

                            if (displayCwinRemarks)
                                winMergeRemarkTxt += getMergedPoolLbl(memPools, ["CWA", "CWB", "CWC"]);
                            break;
                        case "FCT":
                        case "TCE":
                        case "TRI":
                            singleOutput.push(<tr id={`poolInv${singleRacePools[idx]}`} className={`rc-inv-row ${i % 2 == 0 ? '' : 'sec'}`}><td>{getMergedPoolLbl(memPools, ["FCT", "TCE", "TRI"])}</td><td>$ {parseInt(pData.investment || 0).toLocaleString()}</td></tr>);
                            break;
                        case "QTT":
                        case "FF":
                            singleOutput.push(<tr id={`poolInv${singleRacePools[idx]}`} className={`rc-inv-row ${i % 2 == 0 ? '' : 'sec'}`}><td>{getMergedPoolLbl(memPools, ["QTT", "FF"])}</td><td>$ {parseInt(pData.investment || 0).toLocaleString()}</td></tr>);
                            break;
                        default:
                            break;
                    }
                    appendPools.push.apply(appendPools, memPools);
                }
                else {
                    singleOutput.push(<tr id={`poolInv${singleRacePools[idx]}`} className={`rc-inv-row ${i % 2 == 0 ? '' : 'sec'}`}><td>{t('LB_' + singleRacePools[idx])}</td><td>$ {parseInt(pData.investment || 0).toLocaleString()}</td></tr>);
                }
                singleTotal += parseInt(pData.investment || 0);
                allTotal += parseInt(pData.investment || 0);
                i++;
            }
        }

        for (let idx=0; idx<multiRacePools.length; idx++) {
            let pData = mtg.poolInvs.filter(data=> data.leg.races[0]==context.content.raceNo && data.oddsType==multiRacePools[idx])[0];
            if (pData != null) {
                multiOutput.push(<tr id={`poolInv${multiRacePools[idx]}`} className={`rc-inv-row ${idx % 2 == 0 ? '' : 'sec'} ${i % 2 == 0 ? '' : 'm-sec'}`}><td>{t('LB_' + multiRacePools[idx])}</td><td>$ {parseInt(pData.investment || 0).toLocaleString()}</td></tr>);
                allTotal += parseInt(pData.investment || 0);
                i++;
            }
        }

        output = <div className="rcPoolInvTop">
            <div className="rcPoolInvTitle">{t('LB_RC_POOLINV_TOTAL_INVESTMENT')}</div>
            <table className="rc-inv-table rc-inv-table-container">
                <tr className="rc-inv-table-row">
                    <td>
                        <table className="rc-inv-table rc-inv-table-left" id={`singleInvTable_${context.content.raceNo}`}>
                            {singleOutput}
                        </table>
                    </td>
                    <td>
                        <table className="rc-inv-table" id={`multiInvTable_${context.content.raceNo}`}>
                            {multiOutput}
                            {!isComingleRace ?
                                <tr id="raceTotalSingle" className="rc-inv-row rc-inv-row-total">
                                    <td>
                                        {t('LB_RC_POOLINV_RACE_TOTAL_SINGLE')}
                                    </td>
                                    <td>
                                        $ {singleTotal.toLocaleString()}
                                    </td>
                                </tr> : null}
                            {!isComingleRace ? <tr className="rc-inv-row rc-inv-row-total">
                                <td id="raceTotalAll" >
                                    {t('LB_RC_POOLINV_RACE_TOTAL_ALL')}
                                </td>
                                <td>
                                    $ {allTotal.toLocaleString()}
                                </td>
                            </tr> : null}
                            {showGrandTotal ? <tr className="rc-inv-row rc-inv-row-total">
                                <td id="meetingTotal" >
                                    {t('LB_RC_POOLINV_TOTAL_TURNOVER')}
                                </td>
                                <td>
                                    $ {parseInt(mtg.totalInvestment || 0).toLocaleString()}
                                </td>
                            </tr> : null
                            }

                        </table>
                    </td>
                </tr>
            </table>
            {showWinMergeRemarks ? <div id="mergeRmk" className="poolinv-total-remarks">{winMergeRemarkTxt}</div> : null}
            {showGrandTotal ? <div id="poolTotalRmk" className="poolinv-total-remarks">{t('LB_RC_POOLINV_TOTAL_REMARKS')}</div> : null}
        </div>;
    }

    return output;
}
export default PoolInvTable;