import { useWindowSize } from '../../Common/CommonHooks';
import { useParams } from "react-router-dom";

export const useCashOut = () => {

    const { isMobileSize } = useWindowSize();

    const loadStateDisDomFn = (bool = false) => {
        let mobileDisDomArr = ['.fMenuTR-mobile', '.important-notice', '.date-competition-selection', '.match-dropdown-container']
        let desktopDisDomArr = ['.betslip-menu', '.logout', '.leftMenuMain', '.fMenuTR', '.topInfoMenu', '.important-notice', '.topMenu', '.footer', '.date-competition-selection', '.match-dropdown-container', '.refresh-odds']
        if(location.pathname.search('cashout') > -1){
            mobileDisDomArr = ['.fMenuTR-mobile', '.fb-cashout-header', '.cashoutTabs', '.cashOutImportantNotice', '.refreshContainer', '.botMenuNavigBox']
            desktopDisDomArr = ['.betslip-menu', '.logout', '.leftMenuMain', '.fMenuTR', '.topInfoMenu', '.cashoutTabs', '.important-notice', '.topMenu', '.footer', '.refresh-odds', '.cashoutTabsContainer']
        }
        const disDomArr = isMobileSize ? mobileDisDomArr : desktopDisDomArr;
        for (let i = 0; i < disDomArr.length; i++) {
            toggleClassN(document.querySelector(`${disDomArr[i]}`), bool)
        }
    }

    const toggleClassN = (ele, bool) => {
        if (!ele) { return ; }
        const className = `cashoutInProcess`
        let classString = ele.className
        const nameIndex = classString.indexOf(className)
        if (nameIndex < 0 && bool) {
            classString += ' ' + className
        } else if (nameIndex >= 0 && !bool) {
            classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
        }
        ele.className = classString
    }


    return {
        loadStateDisDomFn,
    }

}