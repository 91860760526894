import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate  } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { EmptyTable } from '../../Common/EwinHomeCommon';
import { LandingContext } from '../../Home/LangingPage';
import { useBetSlip, AddM6SlipFunc } from '../../BetSlip/BetSlipHooks'
import { useQuery } from '@apollo/client';
import { getMatchQuery } from '../Data/DataQuery';
import {updateOriginLocation} from '../../Common/ui-components/AddToSlipAnimation';
import { useWindowSize } from '../../Common/CommonHooks';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { errRedirectPage } from '../../Common/utils';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { gql } from '@apollo/client';
import { Query } from '../../Common/graphqlFetch'
import { scrollTo } from '../../Common/GlobalFunc';

const CustomScrollbars = props => (
    <Scrollbars
        autoHeight
        autoHeightMin={70}
        autoHeightMax={70}
        // autoHide
        // autoHideTimeout={1000}
        // autoHideDuration={200}
        {...props}
    />
);

const EwinHomeSGATable = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const { isMobileSize } = useWindowSize();
    const context = useContext(LandingContext);
    const [ filteredData, setFilterData ] = useState([]);
    const [ randomMatch, setRandomMatch ] = useState(null)
    const [ betAdded, setBetAdded ] = useState(0);
    const {
        addToBetSlip,
        betLines
    } = useBetSlip()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const querySGATimer = useRef(null)

    // const { data, loading, refetch } = useQuery(gql(getMatchQuery()), {
    //     fetchPolicy: 'no-cache',
    //     notifyOnNetworkStatusChange: true,
    //     variables: {
    //         fbOddsTypes: ["SGA"],
    //         fbOddsTypesM: ["SGA"],
    //         inplayOnly:false,
    //         featuredMatchesOnly:false,
    //         startDate:null,
    //         endDate:null,
    //         tournIds:null,
    //         matchIds:null,
    //         frontEndIds:null,
    //         earlySettlementOnly: false
    //     },
    //     onError:err=>{
    //         errRedirectPage(err);
    //     }
    // });

    const querySGA = async () => {
        await Query({
            variables: {
                fbOddsTypes: ["SGA"],
                fbOddsTypesM: ["SGA"],
                inplayOnly:false,
                featuredMatchesOnly:false,
                startDate:null,
                endDate:null,
                tournIds:null,
                matchIds:null,
                frontEndIds:null,
                earlySettlementOnly: false
            },
            query: getMatchQuery()
        }).then(result => {
            if(result.data){
                setData(result?.data)
                setLoading(false)
            }
        }).catch(error =>{
            //errRedirectPage(error)
        })
    }

    useEffect(()=>{
        querySGA()
    },[])

    useEffect(()=> {
        if ( !loading ) {
            let matches = getFilterMatches(data?.matches);
            setFilterData([...matches]);
            randomSel(matches);
        }
    }, [data, loading]);

    const getFilterMatches = (matches) => {
        let newMatchArr = [];
        matches?.forEach(match => {
            if ( match.isInteractiveServiceAvailable ) {
                match.foPools.forEach(foPool => {
                    if ( foPool.status=='SELLINGSTARTED' ) {
                        let newMatch = {...match};
                        newMatch.foPools = [{...foPool}];
                        newMatchArr.push(newMatch);
                    }
                });
            }
        })
        return newMatchArr;
    }

    const randomSel = (matches) => {
        if ( matches.length > 0 ) {
            let selectedMatch = matches[Math.floor(Math.random() * matches.length)];
            setRandomMatch({...selectedMatch});
            setBetAdded(0);
        }
    }

    const buildSGABet = () => {
        let match = randomMatch;
        let foPool = match.foPools[0];
        let line = foPool.lines[0];
        let comb = line.combinations[0];
        let sel = comb.selections[0];
        let id = `SGA_${line.lineId}_${comb.combId}_${foPool.id}_${foPool.instNo}`;
        return {
            combs: [{
                    id: id,
                    checked: true,
                    matchId: match.id,
                    oddsVal: comb.currentOdds,
                    betType: "SGA",
                    comb: foPool.instNo,
                    poolId: foPool.id,
                    combId: comb.combId,
                    lineId: line.lineId,
                    currentCombNameObj: {
                        selId: null,
                        str: sel.str,
                        name_ch: sel.name_ch,
                        name_en: sel.name_en
                    },
                    currentPool: foPool
                }
            ],
            matchId: match.id,
            betType: "SGA",
            specialLine: null,
            prod: "FB",
            id: id,
            frontEndId: match.frontEndId,
            inplayDelay: false,
            tournamentNameCH: match.tournament.name_ch,
            tournamentNameEN: match.tournament.name_en,
            tournamentCode: match.tournament.code,
            matchTeamEN: `${match.homeTeam.name_en} vs ${match.awayTeam.name_en}`,
            matchTeamCH: `${match.homeTeam.name_ch} 對 ${match.awayTeam.name_ch}`,
            matchInfo: match,
            noOfBets: 1,
            unitBet: 10
        }
    }

    const addSGABet = () => {
        if(betAdded) return false
        let sgaBet = buildSGABet()
        if(betLines.find(i => i.id === sgaBet.id)) {
            window.alert(t('LB_BS_ADDTOSLIP_FB_ERR_MSG1'))
            return
        }
        if ( addToBetSlip([buildSGABet()]) ) {
            if(isMobileSize){
                setBetAdded(1);
                updateOriginLocation(`.FBSGABlock .blockAddToSlip`,'FTCBall');
                setTimeout(() => {
                    setBetAdded(2);
                }, 700);
            }else{
                setBetAdded(2);
            }
        }
    }

    const clickBlockFootballRandom = async (filteredData) =>{
        if(!querySGATimer.current){
            querySGATimer.current = true
            querySGA()
            setTimeout(()=>{
                querySGATimer.current =false
            },3000)
        }
        randomSel(filteredData)
    }

    const ClickHereLink = ({lbl}) => {
        return <span className="blockFootballLink" onClick={()=>{ scrollTo(0, 0); navigate(`/${i18n.language}/football`) }}>{lbl}</span>
    }

    const FBNotOfferMsg = () => {
        return <><div className="blockMsg2">
            <div>{t('LB_FB_INFO_NOOFFER1')}</div>
            <div>{t('LB_FB_INFO_NOOFFER2')}<ClickHereLink lbl={t('LB_FB_INFO_NOOFFER3')} />{t('LB_FB_INFO_NOOFFER4')}</div>
        </div>
        <div></div>
        </>
    }

    const BetAddMsg = () => {
        return <div className="blockMsg">
            <div>{t('LB_FB_INFO_RETRY_MSG1')}</div>
            <div>{t('LB_FB_INFO_RETRY_MSG2')}<ClickHereLink lbl={t('LB_CLICK_HERE')} />{t('LB_FB_INFO_RETRY_MSG3')}</div>
            <div>{t('LB_FB_INFO_RETRY_MSG4')}</div>
        </div>
    }

    const SGASelection = ({match}) => {
        let comb = randomMatch?.foPools[0].lines[0].combinations[0];
        return comb ? `${comb.selections[0]["name_"+i18n.language]} @ ${comb.currentOdds}` : null;
    }

    const SGATable = () => {
        return <div className="blockBody">
            <div className="blockSGAHead bottom-border">
                <div className='font-weight500'>{t('LB_FB_TITLE_SGA')}</div>
                <div>
                    <ArrowIcon className='cursor-point' onClick={()=>{ scrollTo(0, 0); navigate(`/${i18n.language}/football/sga`) }} direction='right' size={24}/>
                </div>
            </div>
            {filteredData.length===0 ? <FBNotOfferMsg />
            :
                <>
                    <div className="blockSGATable">
                    {betAdded === 2 ? <BetAddMsg /> :
                        <CustomScrollbars>
                            <div className="matchInfo">{randomMatch?.tournament["name_"+i18n.language]} | {randomMatch?.homeTeam["name_"+i18n.language]} {t('LB_FB_VS')} {randomMatch?.awayTeam["name_"+i18n.language]}</div>
                            <div className="sgaDtls"><SGASelection match={randomMatch} /></div>
                        </CustomScrollbars>
                    }
                    </div>
                    <div className="blockButton">
                        <div className="blockFootballRandom btn cta_fb" onClick={()=> {clickBlockFootballRandom(filteredData)}}>{t('LB_RANDOM')}</div>
                        {betAdded !== 2 && <div className="blockAddToSlip cta_yellow btn" onClick={()=> { addSGABet();glassboxCustomEvent("Add_to_Slip") }}>{t('LB_ADDTOSLIP')}</div>}
                    </div>
                </>
            }
        </div>
    }

    return (
        <section className="FBSGABlock">

            {loading ? <EmptyTable/> : <SGATable />}

        </section>
    );
}
export default EwinHomeSGATable;