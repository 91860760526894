import moment from 'moment';
import { checkMatchBySearchparams } from '../../Common/FBSearchHooks';
import { getCellId, isSellingStarted } from '../../Common/Common';
import { isMatchKickOff } from '../../Common/MatchBase';
import { isFeaturedMatch } from '../../Common/Common';
import {isDisplayMatch} from '../useGetMatchList/common'
import { setAllUpCalEntries, getAllUpCalEntries } from '../../Common/AddBet'

const replaceRacingTopicString = (contentRef, tKey) => {
    return contentRef.current.config.pushTopics[tKey]
}

export const getFootballTopics = (contentRef, disableFBOddsPush) => {
    let list = []
    switch (contentRef.current.page) {
        case 'CHP':
        case 'FINALIST':
        case 'WINCNTY':
        case 'WINCNTT':
        case 'TQL':
        case 'TOURNAMENT':
        case 'OFM':
        case 'MIXALLUP_DETAIL':
        case 'MIXALLUP':
        case 'INPLAY_ALL':
        case 'ALLODDS':
        case 'HOME':
        case 'HAD':
        case 'SGA':
        case 'FHA':
        case 'HDC':
        case 'HIL':
        case 'FHL':
        case 'CRS':
        case 'FCS':
        case 'FTS':
        case 'TTG':
        case 'FGS':
        case 'OOE':
        case 'HFT':
        case 'CHL':
        case 'FCH':
        case 'INPLAY':
        case 'MSP':
        case 'HHA':
        case 'FHC':
        case 'CHD':
        case 'FHH':
            if(!disableFBOddsPush){
                list.push(replaceRacingTopicString(contentRef, "TOU_NEW"));
                list.push(replaceRacingTopicString(contentRef, "MAT_NEW"));
                list.push(replaceRacingTopicString(contentRef, "ALLODDS_POO_NEW"));
                list.push(replaceRacingTopicString(contentRef, "TOU_UP"));
                list.push(replaceRacingTopicString(contentRef, "MAT_UP"));
                list.push(replaceRacingTopicString(contentRef, "ALLODDS_POO_UP"));
                list.push(replaceRacingTopicString(contentRef, "TOURNAMENT" + "_POO_UP"));
                list.push(replaceRacingTopicString(contentRef, "TOURNAMENT" +  "_POO_NEW"));
            }
            break
        case 'CASHOUT':
            if(!disableFBOddsPush){
                list.push(replaceRacingTopicString(contentRef, "TOU_NEW"));
                list.push(replaceRacingTopicString(contentRef, "MAT_NEW"));
                list.push(replaceRacingTopicString(contentRef, "ALLODDS_POO_NEW"));
                list.push(replaceRacingTopicString(contentRef, "TOU_UP"));
                list.push(replaceRacingTopicString(contentRef, "MAT_UP"));
                list.push(replaceRacingTopicString(contentRef, "ALLODDS_POO_UP"));
                list.push(replaceRacingTopicString(contentRef, "TOURNAMENT" + "_POO_UP"));
                list.push(replaceRacingTopicString(contentRef, "TOURNAMENT" +  "_POO_NEW"));
            }
            list.push(contentRef.current.config.esTopics["getValueReply"].replace("{0}", sessionStorage.getItem("account")));
            list.push(contentRef.current.config.esTopics["cashoutNormalReply"].replace("{0}", sessionStorage.getItem("account")));
            list.push(contentRef.current.config.esTopics["cashoutInplayReply"].replace("{0}", sessionStorage.getItem("account")));
            break;
    }
        

    return list;
}

export const showPoolByPage = (betType, inplay, page, match) => {
    switch ( page ) {
        case "MIXALLUP":
        case "MIXALLUP_DETAIL":
            return [...window.fbConfig.MixAllupPools, ...window.fbConfig.ExtraTimePools].includes(betType)
        case "INPLAY":
        case "INPLAY_ALL":
            return inplay;
        case "ALLODDS":
        case "OFM":
        case "CASHOUT":
            return true;
        case "HOME":
        case "HAD":
            return ["HAD", "EHA"].some(i => match?.poolInfo?.definedPools?.includes(i));
        case "HDC":
            return ["HDC", "EDC"].some(i => match?.poolInfo?.definedPools?.includes(i));
        case "HIL":
            return ["HIL", "EHL"].some(i => match?.poolInfo?.definedPools?.includes(i));
        case "CHL":
            return ["CHL", "ECH"].some(i => match?.poolInfo?.definedPools?.includes(i));
        case "CRS":
            return ["CRS", "ECS"].some(i => match?.poolInfo?.definedPools?.includes(i));
        case "TTG":
            return ["TTG", "ETG"].some(i => match?.poolInfo?.definedPools?.includes(i));
        default:
            return match?.poolInfo?.definedPools?.some(i => i.includes(page));
    }
}
export const showTournPoolByPage = (betType, chpType, contentRef) => {
    switch ( contentRef.current.page ) {
        case 'CHP':
        case 'WINCNTY':
        case 'WINCNTT':
        case 'FINALIST':
            return betType == 'CHP' && checkChpType(chpType, contentRef)
        case 'TQL':
            return betType == 'TQL'
        default:
            return true;
    }
}

export const isInDisplayCriteria = (match, betType, inplay, contentRef) => {
    if(!contentRef.current.selection) {
        return false;
    }

    if ( !showPoolByPage(betType, inplay, contentRef.current.page, match) ) {
        return false;
    }

    if(!isDisplayMatch(match, contentRef.current.page, contentRef.current.offset )){
        return false
    }

    return match && filterMatch(match, contentRef)
}

export const filterMatch = (match, contentRef) => {
    if(contentRef.current.isESContent) return true
    if([...contentRef.current.config.SingleMatchPages]?.includes(contentRef.current.page)){
        return contentRef.current.matchData?.matches.find(i => i.id === match.id) != null
    }
    if([...contentRef.current.config.MixAllUpDetailPages].includes(contentRef.current.page)){
        return contentRef.current.allupSelection.find(id => id === match.id) != null
    }

    switch (contentRef.current.selection.type) {
        case "DATE":
            let mDate = match.kickOffTime.substring(0,10);
            let curDate = contentRef.current.selection.value[0];
            let tabDate = contentRef.current.selection.tabs.find(x=>x.date==curDate);
            if ( tabDate?.isOther ) {
                return mDate >= tabDate.firstOtherDate;
            }
            else {
                let dateN_plus_one = incr_date(curDate);
                return mDate==curDate || mDate==dateN_plus_one;
            }
        case "TOURN":
            if(contentRef.current.selection.tournAll) return true
            return contentRef.current.selection.value.includes(match.tournament.id);
        case "SEARCH":
            if((!contentRef.current.selection.searchParams?.label && contentRef.current.selection.value.length === 0)){
                return true
            }
            if(contentRef.current.selection.value.includes('OTHER') ) return contentRef.current.matchData?.matches.find(i => i.id === match.id) != null
            if(checkMatchBySearchparams(match, contentRef.current.selection.searchParams)){
                !contentRef.current.selection.value.includes(match.id) && contentRef.current.selection.value.push(match.id)
                return true
            }
            return false
        case "OFM":
            let offset = contentRef.current.offset || 0;
            return isFeaturedMatch(match, offset)
        case "ALL":
            return true;
    }

    return false;
}

export const pushLiveEvtFun =(message)=>{
    let betRadar = message.liveEvt?.feedPdr?.filter(x=> x.pdr=="BETRADAR")[0];
    if ( betRadar?.hasLive ) {
        return {
            code : betRadar.pdr,
            id : betRadar.matchID
        };
    }
    return {
        code: "", id: ""
    }
}

export const incr_date = (date_str) => {
    return moment(date_str).add(1, 'days').format('YYYY-MM-DD');
}

export const matchCompare = ( a, b )=> {
    if( a.matchDate < b.matchDate ){
        return -1;
    }
    if( a.matchDate > b.matchDate ){
        return 1;
    }
    if ( a.sequence?.toLowerCase() < b.sequence?.toLowerCase() ){
        return -1;
    }
    if ( a.sequence?.toLowerCase() > b.sequence?.toLowerCase() ){
        return 1;
    }
    return a.id - b.id;
}
export const featureMatchCompare = ( a, b )=> {
    if ( a.featureMatchSequence < b.featureMatchSequence ){
        return -1;
      }
      if ( a.featureMatchSequence > b.featureMatchSequence ){
        return 1;
      }

    return 0
}

export const listDataCompare = ( a, b )=> {
    if( a.kickOffTime < b.kickOffTime ){
        return -1;
    }
    if( a.kickOffTime > b.kickOffTime ){
        return 1;
    }
    return 0;
}

export const tournamentCompare = ( b, a )=> {
    if( a.id < b.id ){
        return -1;
    }
    if( a.id > b.id ){
        return 1;
    }
    return 0;
}

export const chpTournamentCompare = ( a, b )=> {
    if( a.id < b.id ){
        return -1;
    }
    if( a.id > b.id ){
        return 1;
    }
    return 0;
}

export const wagRunningResultCompare = ( a, b )=> {
    if( a.seqNo < b.seqNo ){
        return -1;
    }
    if( a.seqNo > b.seqNo ){
        return 1;
    }
    return 0;
}

export const removeTimeoutMessage = (msgCache) =>{
    const newCache = {...msgCache}
    const keys = Object.keys(newCache)
    const newDate = moment()
    keys.forEach(key =>{
        newCache[key] = newCache[key].filter(item => newDate.diff(item.updTime, 'second') < 60)
        if(newCache[key].length === 0){
            delete newCache[key]
        }
    })

    return newCache
}

export const addOddsDiff = (contentRef, match, oldPool, newPool, option) => {
    const isReplay = option?.isReplay
    oldPool.lines.forEach(line =>{
        let newLine = newPool.lines.find(x => x.lineId ==  line.lineId);
        line.combinations.forEach(comb =>{
            let id = getCellId(oldPool, line, comb)
            let newComb = newLine?.combinations?.find(i => i.combId == comb.combId)
            let selectionIdx = contentRef.current.oddsSelection.findIndex(x => x.id === id)
            if((!newLine || !newComb || newLine?.status?.toUpperCase() != 'AVAILABLE') && selectionIdx > -1){
                contentRef.current.oddsSelection[selectionIdx].checked = false
            }
        })
    })
    newPool.lines.forEach(line => {
        let oldLine = oldPool.lines.find(x => x.lineId ==  line.lineId);
        line.combinations.forEach(comb => {
            // add oddsDiff
            let id = getCellId(newPool, line, comb);
            let oldComb = oldLine?.combinations?.find(i => i.combId == comb.combId)
            let isSelling = isSellingStarted(match, newPool, line) && comb.status == 'AVAILABLE';
            let oldPoolSelling = isSellingStarted(match, oldPool, oldLine) && oldComb?.status == 'AVAILABLE'
            let isKickOff = isMatchKickOff(match.status)
            let isStopSelltoStartSell = isKickOff && !oldPoolSelling && isSelling
            const lastOdds = oldLine?.combinations?.find(c => c.combId === comb.combId)?.currentOdds
            let oddsDiff = !lastOdds ? 0 : parseFloat(comb.currentOdds) - parseFloat(lastOdds);
            let idx = contentRef.current.oddsSelection.findIndex(x => x.id === id);
            let now = moment();
            if ( idx > -1  ) {
                contentRef.current.oddsSelection[idx].oddsVal = comb.currentOdds;
                if ( oddsDiff!=0 ) {
                    contentRef.current.oddsSelection[idx].oddsDiff = isReplay ? null : oddsDiff;
                    contentRef.current.oddsSelection[idx].oddUpdTs = isReplay ? null : now;
                }else if(isStopSelltoStartSell){
                    contentRef.current.oddsSelection[idx].oddsDiff = 0
                    contentRef.current.oddsSelection[idx].oddUpdTs = null
                }
                if( !isSelling){
                    contentRef.current.oddsSelection[idx].checked = false
                }
            }
            else if(oddsDiff != 0) {
                contentRef.current.oddsSelection.push({
                    id: id,
                    oddsDiff: isReplay ? null : oddsDiff,
                    oddUpdTs: isReplay ? null : now
                })
            }
        });
        
        if(['CRS','FCS'].includes(contentRef.current.page) && contentRef.current.config?.CorrectScorePools.includes(newPool.oddsType)){
            let comb = [...line.combinations].sort((a,b)=> a.currentOdds - b.currentOdds)
            comb.forEach((comb, index) => {
                let id = getCellId(newPool, line, comb)
                if(index > 3 ){
                    let idx = contentRef.current.oddsSelection.findIndex(i => i.id === id)
                    if(idx > -1)  contentRef.current.oddsSelection[idx].checked = false
                }
            })
        }
    });
}

export const updateContent = (oldContent, contentRef, msgCacheRef) =>{
    const newContent = {...contentRef}
    let newSelections = newContent.oddsSelection
    let newMatch = newContent.matchData.matches
    const selections  = oldContent.oddsSelection.reduce((list,next)=>{
        const newSelection = newSelections.find(i => i.id === next.id)
        if(newSelection) {
            newSelections = newSelections.filter(i=> i.id != next.id)
            list.push({...next, ...newSelection})
        }else{
            list.push(next)
        }
        return list
    },[])
    const matches  = oldContent.matchData.matches.reduce((list,next)=>{
        const match = newMatch.find(i => i.id === next.id)
        if(match) {
            newMatch = newMatch.filter(i=> i.id != next.id)
            list.push({...next, ...newMatch})
        }else{
            list.push(next)
        }
        return list
    },[])
    const oddsSelection = [...selections, ...newSelections];
    const newselection = {...oldContent.selection};
    const msgCache = {...msgCacheRef};
    let matchData = [...matches, ...newMatch]
    let curMatchData = matchData.filter(match => filterMatch(match,{...oldContent, matchData:{matches: matchData}}))

    let curContent = {...oldContent,
        oddsSelection,
        selection: newselection,
        msgCache,
        listData: newContent.listData,
        matchData: {matches: curMatchData},
        tournData: newContent.tournData
    }

    return curContent
}

export const updateAllUpCalCookies =(message,newPool) =>{
    const oldAllUpData =  getAllUpCalEntries()
    let curData =  oldAllUpData?.find(i => i.poolId === message.id)
    const curline = curData &&  message.lines.line.find(item => item.id === curData.lineId)
    const curComb = curline && curline.comb.find(i => i.id === curData.combId)
    const match = curData.matchInfo
    if(curData && curline && curComb){
        let oldOdds = curData.oddsVal
        let now = moment()
        let lineSell =  curline.st?.toUpperCase() =='AVAILABLE';
        let poolSell =  message?.st?.toUpperCase() == 'SELLINGSTARTED';
        let combSell = curComb.st.toUpperCase() == 'AVAILABLE'
        const oddsVal = curComb.odds?.cur?.odds
        const isSelling = lineSell && poolSell && combSell && match.isInteractiveServiceAvailable
        const oddsDiff = oddsVal? parseFloat(oddsVal) - parseFloat(oldOdds)  : 0
        curData = {
            ...curData,
            oddsVal,
            foPool: newPool,
            isSelling,
            oddsDiff: oddsDiff || curData.oddsDiff,
            oddUpdTs: oddsDiff ? now : curData.oddUpdTs
        }
        const newllUpData = oldAllUpData.map(item => {
            if(item.poolId === curData.poolId) return curData
            return item
        })
        setAllUpCalEntries(newllUpData)
    }
}

export const matchUpdateMatUpdAllUpCalCookies =(matchid, isInteractiveServiceAvailable) =>{
    const oldAllUpData =  getAllUpCalEntries()
    let curData =  oldAllUpData?.find(i => i.id === matchid)
    let curPool = curData?.foPool
    const curline = curPool &&  curPool.lines.find(i=> i.lineId == curData.lineId)
    const curComb = curline && curline.combinations.find(i => i.combId === curData.combId)
    if(curData &&curline && curComb &&  curPool){
        curData.matchInfo.isInteractiveServiceAvailable = isInteractiveServiceAvailable
        let lineSell =  curline.status?.toUpperCase() =='AVAILABLE';
        let poolSell =  curPool?.status?.toUpperCase() == 'SELLINGSTARTED';
        let combSell = curComb.status.toUpperCase() == 'AVAILABLE'
        const isSelling = lineSell && poolSell && combSell && isInteractiveServiceAvailable
        curData = {
            ...curData,
            isSelling
        }
        const newllUpData = oldAllUpData.map(item => {
            if(item.poolId === curData.poolId) return curData
            return item
        })
        setAllUpCalEntries(newllUpData)
    }
}

export const isMatchVoided = (status) => {
    return [ "INPLAYRESULTVOIDED", "MATCHVOIDED", "RESULTVOIDED", "MATCHSUSPENDED", 'INPLAYVOIDED' ].includes(status?.toUpperCase());
}

export const diffAllUpSelection = (contentRef) => {
    contentRef.current.oddsSelection = contentRef.current.oddsSelection.map(item =>{
        if(item.checked && item.oddsType == "MIXALLUP"){
            const [oddsType, matchId] = item.id.split('_')
            if( !contentRef.current.listData.matches.find(i => i.id == matchId )){
                return {...item,
                    checked: false
                }
            }
        }
        else if( item.checked && contentRef.current.page == "MIXALLUP_DETAIL" ){
            if( !contentRef.current.matchData.matches.find(i => i.id == item.matchId )){
                return {...item,
                    checked: false
                }
            }
        }
        return item
    })
}


export const checkINPLAYandOFMPool = (message, contentRef) =>{
    if(['INPLAY', 'OFM'].includes(contentRef.current.page) && !['EHA', 'HAD'].includes(message.bTyp.toUpperCase())) return false
    return true
}

export const checkChpType = (chpType, contentRef) =>{
    switch (chpType + ''){
        case '1':
            return contentRef.current.page === 'WINCNTY'
        case '2':
            return contentRef.current.page === 'WINCNTT'
        case '3':
            return contentRef.current.page === 'FINALIST'
        case '0':
            return contentRef.current.page === 'CHP'
    }
}