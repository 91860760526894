import { getOddsValue } from "./common";

export const parseFullOdds = (pmPool, message) => {
    if(message != null){
        if(message.updAt != null && message.cmb !=null){
            pmPool.lastUpdateTime = message.updAt;
            message.cmb.forEach(comb => {
                let isCwin = ["CWA", "CWB", "CWC"].includes(pmPool.oddsType);
                let targetComb = pmPool.oddsNodes.find(x=> {
                    let combStr = isCwin ? x.combString.substring(1) : x.combString;
                    return combStr===comb.cmbStr
                });
                if ( targetComb!=null ) {
                    targetComb.oddsValue = getOddsValue(comb.odds, comb.cmbSt);
                    targetComb.hotFavourite = comb.hf===true;
                    targetComb.oddsDropValue = parseInt(comb.oDrp) || 0;
                }else{
                    pmPool.oddsNodes.push({
                        oddsValue :getOddsValue(comb.odds, comb.cmbSt),
                        hotFavourite : comb.hf===true,
                        oddsDropValue : parseInt(comb.oDrp) || 0,
                        combString: comb.cmbStr
                    })
                }
            });
        }
    }
}

export const parseLast2Mins = (pmPool, message) => {
    if(message?.oddsSnapshot?.last2Min != null){
        if(message.oddsSnapshot.last2Min.updAt != null && message.oddsSnapshot.last2Min.cmb!=null){
            pmPool.lastUpdateTime = message.oddsSnapshot.last2Min.updAt;
            message.oddsSnapshot.last2Min.cmb.forEach(comb => {
                let targetComb = pmPool.oddsNodes.filter(x=> {
                    let combStr = x.combString;
                    return combStr===comb.cmbStr
                })[0];
                if ( targetComb!=null ) {
                    targetComb.oddsValue = getOddsValue(comb.odds, comb.cmbSt);
                }
            });
        }
    }
}

export const parseFirstNamed = (pmPool, message) => {
    if(message?.oddsSnapshot?.firstNamed != null){
        if(message.oddsSnapshot.firstNamed.updAt != null && message.oddsSnapshot.firstNamed.cmb!=null){
            pmPool.lastUpdateTime = message.oddsSnapshot.firstNamed.updAt;
            message.oddsSnapshot.firstNamed.cmb.forEach(comb => {
                let targetComb = pmPool.oddsNodes.filter(x=> {
                    let combStr = x.combString;
                    return combStr===comb.cmbStr
                })[0];
                if ( targetComb!=null ) {
                    targetComb.oddsValue = getOddsValue(comb.odds, comb.cmbSt);
                }
            });
        }
    }
}