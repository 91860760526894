import React,{useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient } from '@apollo/client';
import { getAllMatchListQuery } from '../DataQuery';
import { getDisplayMatches } from './common';
import useGetMatches  from '../useGetMatches';
import { getQueryDateRange, getQueryMatchIdForDateTab,
    getQueryMatchIdForTournTab, getQueryTorunIds } from '../../Common/Common';
import { updateMixAllUpOFMMatches } from './common';
import { Query, apiErrorCallback } from '../../../Common/graphqlFetch'
import { setAllUpCalEntries, getAllUpCalEntries } from '../../Common/AddBet'

const useGetMatchList = ({contentRef, setContent, fbSearchHooks, disableRefetch}) => {
    const params = useParams();
    const matchIdRef = useRef()
    matchIdRef.current = params.matchId
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const { loadMatchQuery, reloadMatchQuery, reloadCalMatchQuery } = useGetMatches(contentRef, setContent);
    const matchListTimerRef = useRef(null)

    const apolloClient = useApolloClient();

    const loadMatchListQuery = (callbackFunc, isInit) => {
        clearTimeout(matchListTimerRef.current)
        Promise.all([Query({
            query : getAllMatchListQuery(),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then(([results]) => {
            contentRef.current.rawMatchList = results?.data?.matches || [];

            // to be replaced by graphQL call
            // let _tournById = contentRef.current.rawMatchList.reduce((init, next) => {
            //     let key = next.tournament.id;
            //     init[key] = next.tournament;
            //     return init;
            // }, {});
            // contentRef.current.rawTournList = Object.keys(_tournById).map(x=> _tournById[x]);

            contentRef.current.offset = results?.data?.timeOffset?.fb || 0;
            let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
            contentRef.current.listData = { matches : matchList };
            if ( callbackFunc ) {
                callbackFunc();
            }
            else {
                setMatchListToContent(isInit);
            }
        }).catch(error => apiErrorCallback(error, ()=>{ loadMatchListQuery(callbackFunc) },{timer: matchListTimerRef.current, disableRefetch} ));
    }

    const setMatchListToContent = (isInit) => {
        contentRef.current.isApiErr = false;
        if (contentRef.current.page=="CASHOUT") {
            // donothing
        }
        else if (contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {
            let mIds = contentRef.current.allupSelection;
            loadMatchQuery({ page: contentRef.current.page, matchIds: mIds });
        } else if ( contentRef.current.listData.matches.length > 0 ) {
            switch (contentRef.current.selection.type) {
                case "SEARCH":
                    let para = {};
                    if(contentRef.current.selection?.searchParams?.label){
                            para = { page: contentRef.current.page, matchIds: contentRef.current.selection.value };
                            fbSearchHooks?.setSearchText(contentRef.current.selection?.searchParams?.label)
                    }else{
                        if(contentRef.current.config.MixAllUpPages.includes(contentRef.current.page)){
                            contentRef.current.matchData = { matches : contentRef.current.listData.matches }
                            setTimeout(()=>{
                                contentRef.current.isLoading = false;
                                contentRef.current.isApiErr = false;
                                setContent({... contentRef.current})
                            })
                            return
                        }
                        para = { page: contentRef.current.page, startIndex: 1, endIndex: contentRef.current.config.MaxPaginationLimit };
                    }
                    loadMatchQuery(para);
                    break
                case 'DATE':
                    if (contentRef.current.config.AllOddsPages.includes(contentRef.current.page)) {
                        let mIds = getQueryMatchIdForDateTab(contentRef.current.listData.matches, contentRef.current.offset, contentRef.current, matchIdRef.current);
                        let url = `/${i18n.language}/football/${contentRef.current.page.toLowerCase()}/${mIds[0]}`;
                        if ( !matchIdRef.current )
                            navigate(url, { state: { matchId: mIds[0] }, replace: true });
                        loadMatchQuery({ page: contentRef.current.page, matchIds: [mIds[0]] });
                    }
                    else {
                        let [dateFrom, dateTo] = getQueryDateRange(contentRef.current.listData.matches, contentRef.current.offset, contentRef.current);
                        let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                        contentRef.current.listData = { matches : matchList };
                        setContent({ ...contentRef.current });
                        loadMatchQuery({ page: contentRef.current.page, dateFrom: dateFrom, dateTo: dateTo });
                    }
                    break;
                case 'TOURN':
                    let npId = null;
                    let selectAll = false
                    if ( matchIdRef.current?.startsWith('t') ) {
                        npId = matchIdRef.current.substring(1);
                    }
                    if(isInit && contentRef.current.selection?.value?.[0] && contentRef.current.selection?.value?.[0] != 'all'){
                        npId = contentRef.current.selection?.value?.[0]
                    }
                    if(isInit && contentRef.current.selection?.value?.[0] == 'all'){
                        selectAll = true
                    }
                    if (contentRef.current.config.AllOddsPages.includes(contentRef.current.page)) {
                        const allOddsNpId = (isInit && contentRef.current.selection?.value?.[0] && contentRef.current.selection?.value?.[0] != 'all') ? contentRef.current.selection?.value?.[0] : null
                        let mIds = getQueryMatchIdForTournTab(contentRef.current.listData.matches, contentRef.current, matchIdRef.current, allOddsNpId, selectAll);
                        let url = `/${i18n.language}/football/${contentRef.current.page.toLowerCase()}/${mIds[0]}`;
                        if ( matchIdRef.current==null )
                            navigate(url, { state: { matchId: mIds[0] }, replace: true });
                        loadMatchQuery({ page: contentRef.current.page, matchIds: [mIds[0]] });
                    }
                    else if (contentRef.current.config.MixAllUpPages.includes(contentRef.current.page)) {
                        let newTournIds = getQueryTorunIds(contentRef.current.listData.matches, contentRef.current, npId,selectAll )
                        let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                        contentRef.current.listData = { matches : matchList };
                        contentRef.current.matchData = { matches : contentRef.current.listData.matches.filter(x=> newTournIds.includes(x.tournament.id)) };
                        contentRef.current.isLoading = false;
                        setTimeout(()=>{
                            setContent({ ...contentRef.current });
                        })
                    }
                    else {
                        let newTournIds = getQueryTorunIds(contentRef.current.listData.matches, contentRef.current, npId, selectAll)
                        let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                        contentRef.current.listData = { matches : matchList };
                        loadMatchQuery({ page: contentRef.current.page, tournIds: newTournIds });
                    }
                    break;
                case 'ALL':
                    if (contentRef.current.config.AllOddsPages.includes(contentRef.current.page) ) {
                        let curMatchId = contentRef.current.listData.matches.find(item => item.id === matchIdRef.current)?.id
                        if( !curMatchId && !contentRef.current.fromES ){
                            curMatchId =  contentRef.current.listData.matches[0].id;
                            let url = `/${i18n.language}/football/${contentRef.current.page.toLowerCase()}/${curMatchId}`;
                            navigate(url, { state: { matchId: curMatchId }, replace: true });
                            contentRef.current.selection.tabs = [];
                        }
                        loadMatchQuery({ page: contentRef.current.page, matchIds: [curMatchId] });
                    }
                    else if (contentRef.current.config.MixAllUpPages.includes(contentRef.current.page)){
                        contentRef.current.matchData = { matches : contentRef.current.listData.matches }
                        setTimeout(()=>{
                            contentRef.current.isLoading = false;
                            contentRef.current.isApiErr = false;
                            setContent({... contentRef.current})
                        })
                    }
                    else {
                        contentRef.current.selection.tabs = [];
                        loadMatchQuery({ page: contentRef.current.page, startIndex: 1, endIndex: contentRef.current.config.MaxPaginationLimit});
                    }
                    break;
                case 'OFM': // For MixAllUp Only
                    updateMixAllUpOFMMatches(contentRef, setContent)
                    break;
            }
        }
        else {
            contentRef.current.isLoading = false;
            setContent({ ...contentRef.current });
        }
    }

    const getAllUpCalMatchIds = (AllUpCalData) =>{
        return AllUpCalData.map(i => i.id)
    }

    const resetMatchListToContent = ({inplayOnly=false}) => {
        contentRef.current.isApiErr = false;
        const AllUpCalData =  getAllUpCalEntries()
        if(AllUpCalData?.length > 0){
           let mIds =  getAllUpCalMatchIds(AllUpCalData)
           reloadCalMatchQuery(mIds)
        }
    
        if (contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {
            let mIds = contentRef.current.allupSelection;
            reloadMatchQuery({ page: contentRef.current.page, inplayOnly, matchIds: mIds });
        } else if ( contentRef.current.listData.matches.length > 0 ) {
            switch (contentRef.current.selection.type) {
                case "SEARCH":
                    let para = {};
                    if (contentRef.current.selection.value.length > 0 || contentRef.current.selection?.searchParams?.label){
                        let matchIds = contentRef.current.selection.value
                        if(contentRef.current.selection.value[0] == 'OTHER'){
                            matchIds = contentRef.current?.matchData?.matches?.map(i => i.id)
                        }
                        if(matchIds.length == 0) return
                        para = { page: contentRef.current.page, inplayOnly, matchIds: matchIds};
                    }else  {
                        if(contentRef.current.config.MixAllUpPages.includes(contentRef.current.page)){
                            contentRef.current.matchData = { matches : contentRef.current.listData.matches }
                            return
                        }
                        para = { page: contentRef.current.page, inplayOnly, startIndex: 1, endIndex: contentRef.current.config.MaxPaginationLimit * contentRef.current.pageNum };
                    }
                    reloadMatchQuery(para);
                    break
                case 'DATE':
                    if (contentRef.current.config.AllOddsPages.includes(contentRef.current.page)) {
                        let queryId
                        let mIds = getQueryMatchIdForDateTab(contentRef.current.listData.matches, contentRef.current.offset, contentRef.current, matchIdRef.current);
                        queryId = mIds[0]
                        let curMatch = contentRef.current.matchData.matches.find(item => item.id === matchIdRef.current)
                        let url = `/${i18n.language}/football/${contentRef.current.page.toLowerCase()}/${mIds[0]}`;
                        if ( !matchIdRef.current ){
                            navigate(url, { state: { matchId: mIds[0] }, replace: true });
                        }
                        if(curMatch){
                            queryId = curMatch.id
                        }
                        reloadMatchQuery({ page: contentRef.current.page, inplayOnly, matchIds: [queryId] });
                        // reloadMatchQuery({ page: contentRef.current.page, matchIds: [matchIdRef.current] });
                    }
                    else {
                        let [dateFrom, dateTo] = getQueryDateRange(contentRef.current.listData.matches, contentRef.current.offset, contentRef.current);
                        let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                        contentRef.current.listData = { matches : matchList };
                        setContent({ ...contentRef.current });
                        reloadMatchQuery({ page: contentRef.current.page, inplayOnly, dateFrom: dateFrom, dateTo: dateTo });
                    }
                    break;
                case 'TOURN':
                    let npId = null;
                    if ( matchIdRef.current?.startsWith('t') ) {
                        npId = matchIdRef.current.substring(1);
                    }
                    if (contentRef.current.config.AllOddsPages.includes(contentRef.current.page)) {
                        let queryId
                        let mIds = getQueryMatchIdForTournTab(contentRef.current.listData.matches, contentRef.current, matchIdRef.current);
                        queryId = mIds[0]
                        let curMatch = contentRef.current.matchData.matches.find(item => item.id === matchIdRef.current)
                        let url = `/${i18n.language}/football/${contentRef.current.page.toLowerCase()}/${mIds[0]}`;
                        if ( matchIdRef.current==null)
                            navigate(url, { state: { matchId: mIds[0] }, replace: true });
                        if(curMatch){
                            queryId = curMatch.id
                        }
                            reloadMatchQuery({ page: contentRef.current.page, inplayOnly, matchIds: [queryId] });
                        // reloadMatchQuery({ page: contentRef.current.page, matchIds: [matchIdRef.current] });
                    }
                    else if (contentRef.current.config.MixAllUpPages.includes(contentRef.current.page)) {
                        let newTournIds = getQueryTorunIds(contentRef.current.listData.matches, contentRef.current, npId)
                        let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                        contentRef.current.listData = { matches : matchList };
                        contentRef.current.matchData = { matches : contentRef.current.listData.matches.filter(x=> newTournIds.includes(x.tournament.id)) };
                        contentRef.current.isLoading = false;
                        setTimeout(()=>{
                            setContent({ ...contentRef.current });
                        })
                    }
                    else {
                        let newTournIds = getQueryTorunIds(contentRef.current.listData.matches,  contentRef.current, npId)
                        let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                        contentRef.current.listData = { matches : matchList };
                        reloadMatchQuery({ page: contentRef.current.page, inplayOnly, tournIds: newTournIds });
                    }
                    break;
                case 'ALL':
                    if (contentRef.current.config.AllOddsPages.includes(contentRef.current.page) ) {
                        let curMatchId = contentRef.current.listData.matches.find(item => item.id === matchIdRef.current)?.id
                        let curMatch = contentRef.current.matchData.matches.find(item => item.id === matchIdRef.current)?.id
                        // if( !curMatchId ){
                        //     curMatchId =  contentRef.current.listData.matches[0].id;
                        //     let url = `/${i18n.language}/football/${contentRef.current.page.toLowerCase()}/${curMatchId}`;
                        //     navigate(url, { state: { matchId: curMatchId }, replace: true });
                        //     contentRef.current.selection.tabs = [];
                        // }
                        reloadMatchQuery({ page: contentRef.current.page, inplayOnly, matchIds: [curMatch] });
                        // reloadMatchQuery({ page: contentRef.current.page, matchIds: [matchIdRef.current] });
                    }
                    else if (contentRef.current.config.MixAllUpPages.includes(contentRef.current.page)){
                        contentRef.current.matchData = { matches : contentRef.current.listData.matches }
                        setTimeout(()=>{
                            contentRef.current.isLoading = false;
                            contentRef.current.isApiErr = false;
                            setContent({... contentRef.current})
                        })
                    }
                    else {
                        const endIndex = contentRef.current.pageNum * contentRef.current.config.MaxPaginationLimit
                        reloadMatchQuery({ page: contentRef.current.page, inplayOnly, startIndex: 1, endIndex: endIndex});
                    }
                    break;
                case 'OFM': // For MixAllUp Only
                    updateMixAllUpOFMMatches(contentRef, setContent)
                    break;
        }
        }
        else {
            contentRef.current.isLoading = false;
            setContent({ ...contentRef.current });
        }
    }

    return {
        loadMatchListQuery,
        getDisplayMatches,
        setMatchListToContent,
        resetMatchListToContent
    }
}

export default useGetMatchList