import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isLocalMeeting } from '../../Common/home-common';
import { lblBr } from '../../Common/GlobalFunc';
import { RacingContext } from '../../Home/RacingPage';
import { getPwinTimes } from './RCOddsTableBase';

const RCOddsHeader = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    const isLocal = () => {
        return isLocalMeeting(context.content.venue);
    }

    const [pTime0, pTime1, winTime] = getPwinTimes(context);
    const rowSpan = props.columns.pwin0 ? 2 : 1

    return (
        <>
            <tr className="rc-odds-table-header">
                {props.columns.raceNo ? <td rowSpan={rowSpan} className="no">{t('LB_RC_ODDS_TABLE_RACENO')}</td> : null}
                {props.columns.tp ? <td rowSpan={rowSpan} className="tp">{t('LB_RC_ODDS_TABLE_TP')}</td> : null}
                {props.columns.no ? <td rowSpan={rowSpan} className="no">{t('LB_RC_ODDS_TABLE_NO')}</td> : null}
                {props.columns.colour ? (!props.columns.showStandby ? <td rowSpan={rowSpan} className="colour">{t('LB_RC_ODDS_TABLE_COLOUR')}</td> : <td rowSpan={rowSpan}></td>) : null}
                {props.columns.horseName ? <td rowSpan={rowSpan} className="horseName">{t('LB_RC_ODDS_TABLE_HRNAME')}</td> : null}
                {props.columns.rcNote && isLocal() ? <td rowSpan={rowSpan} className="RCNOTE">{lblBr(t('LB_RC_ODDS_TABLE_RCNOTE_BR'))}</td> : null}
                {props.columns.draw ?  (!props.columns.showStandby ? <td rowSpan={rowSpan} className="draw">{t('LB_RC_ODDS_TABLE_DRAW')}</td> : <td rowSpan={rowSpan}></td>) : null}
                {props.columns.wt ? <td rowSpan={rowSpan} className="wt">{t('LB_RC_ODDS_TABLE_WT')}</td> : null}
                {props.columns.jocky ?  (!props.columns.showStandby ? <td rowSpan={rowSpan} className="jocky">{t('LB_RC_ODDS_TABLE_JOCKEY')}</td> : <td rowSpan={rowSpan}></td>) : null}
                {props.columns.trainer ? <td rowSpan={rowSpan} className="trainer">{t('LB_RC_ODDS_TABLE_TRAINER')}</td> : null}
                {props.columns.bodyWT ? <td rowSpan={rowSpan} className="bodyWT">{t('LB_RC_ODDS_TABLE_BODYWT')}</td> : null}
                {props.columns.intRtg ? <td rowSpan={rowSpan} className="rtg">{lblBr(t('LB_RC_ODDS_TABLE_INT_RTG'))}</td> : null}
                {props.columns.rtg ? <td rowSpan={rowSpan} className="rtg">{isLocal() ? t('LB_RC_ODDS_TABLE_RTG') : lblBr(t('LB_RC_ODDS_TABLE_INT_RTG'))}</td> : null}
                {props.columns.gear ? <td rowSpan={rowSpan} className="gear">{t('LB_RC_ODDS_TABLE_GEAR')}</td> : null}
                {props.columns.last6 ? <td rowSpan={rowSpan} className="last6">{isLocal() ? t('LB_RC_ODDS_TABLE_L6R') : t('LB_RC_ODDS_TABLE_L5R')}</td> : null}
                {props.columns.pwin0 ? <td className="pwinCol" colSpan={3}>
                    {t('LB_RACE_PWIN')}
                </td> :null }
                {props.columns.win && !props.columns.pwin0 ? <td rowSpan={rowSpan} className="pwinCol win">{t('LB_RC_ODDS_TABLE_WIN')}</td> : null}
                {props.columns.place ? <td rowSpan={rowSpan} className="place">{t('LB_RC_ODDS_TABLE_Place')}</td> : null}
                {props.columns.wp ? <td rowSpan={rowSpan} className="wp">{t('LB_RC_ODDS_TABLE_WINPlace')}</td> : null}
            </tr>
            {props.columns.pwin0 ? <tr>
                <td className='pwinCol0'>{pTime0}</td>
                {props.columns.pwin1 ? <td className='pwinCol1'>{pTime1}</td> : <td></td>}
                {props.columns.pwin0 ? <td className='win winTime'>{winTime}</td>:<td></td>}
            </tr> :null }
        </>
    )
}
export default RCOddsHeader;

// export const clipboardHeader = (columns, context, t) => {
//     const isLocal = () => {
//         return isLocalMeeting(context.content.venue);
//     }

//     const [pTime0, pTime1, winTime] = getPwinTimes(context);

//     return `<tr>
//             ${columns.raceNo ? `<td>${t('LB_RC_ODDS_TABLE_RACENO')}</td>` : ""}
//             ${columns.tp ? `<td>${t('LB_RC_ODDS_TABLE_TP')}</td>` : ""}
//             ${columns.no ? `<td>${t('LB_RC_ODDS_TABLE_NO')}</td>` : ""}
//             ${columns.colour ? `<td>${!columns.showStandby ? t('LB_RC_ODDS_TABLE_COLOUR') : ""}</td>` : ""}
//             ${columns.horseName ? `<td>${t('LB_RC_ODDS_TABLE_HRNAME')}</td>` : ""}
//             ${columns.draw ?  `<td>${!columns.showStandby ? t('LB_RC_ODDS_TABLE_DRAW') : ""}</td>` : ""}
//             ${columns.wt ? `<td>${t('LB_RC_ODDS_TABLE_WT')}</td>` : ""}
//             ${columns.jocky ?  `<td>${!columns.showStandby ? t('LB_RC_ODDS_TABLE_JOCKEY') : ""}</td>` : ""}
//             ${columns.trainer ? `<td>${t('LB_RC_ODDS_TABLE_TRAINER')}</td>` : ""}
//             ${columns.bodyWT ? `<td>${t('LB_RC_ODDS_TABLE_BODYWT')}</td>` : ""}
//             ${columns.intRtg ? `<td>${lblBr(t('LB_RC_ODDS_TABLE_INT_RTG'))}</td>` : ""}
//             ${columns.rtg ? `<td>${t('LB_RC_ODDS_TABLE_RTG')}</td>` : ""}
//             ${columns.gear ? `<td>${t('LB_RC_ODDS_TABLE_GEAR')}</td>` : ""}
//             ${columns.last6 ? `<td>${isLocal() ? t('LB_RC_ODDS_TABLE_L6R') : t('LB_RC_ODDS_TABLE_L5R')}</td>` : ""}
//             ${columns.pwin0 ? `<td>${pTime0}</td>` : ""}
//             ${columns.pwin1 ? `<td>${pTime1}</td>` : ""}
//             ${columns.win && columns.pwin0 ? `<td>${winTime}</td>` : ""}
//             ${columns.win && !columns.pwin0 ? `<td>${t('LB_RC_ODDS_TABLE_WIN')}</td>` : ""}
//             ${columns.place ? `<td>${t('LB_RC_ODDS_TABLE_Place')}</td>` : ""}
//         </tr>`;
// }