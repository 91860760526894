import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate  } from 'react-router-dom';
import { EmptyTable } from '../../Common/EwinHomeCommon';
import { formatRacingDate, getDefaultMeeting, isStartSellpmPool } from './Common';
import { capitalize, NewWindow } from '../../Common/home-common';
import { LandingContext } from '../../Home/LangingPage';
import { useBetSlip } from '../../BetSlip/BetSlipHooks'
import {updateOriginLocation} from '../../Common/ui-components/AddToSlipAnimation';
import { useWindowSize } from '../../Common/CommonHooks';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { GetRaceConfig  } from '../../Common/ConfigHelper';


const EwinHomeTTQuickPick = ({ getRacingEosMesssage }) => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const { isMobileSize } = useWindowSize();
    const context = useContext(LandingContext);
    const [ bets, setBets ] = useState([]);
    const [ betAdded, setBetAdded ] = useState(0);
    const { addToBetSlip } = useBetSlip()
    const { data, loading } = context.racingData;
    const defaultMtg = getDefaultMeeting(data?.raceMeetings || [], data?.timeOffset?.rc || 0, GetRaceConfig());

    useEffect(()=> {
        randomSels();
    }, [data]);

    const getComingTTPool = () => {
        const curRaceNo = defaultMtg?.currentNumberOfRace;
        let pools = defaultMtg?.pmPools?.filter(x=> curRaceNo <= x.leg.races[0]);
        pools?.sort((x,y) => { return x.leg.races[0] - y.leg.races[0]});
        return pools?.[0];
    }

    const getTTPoolStatus = () => {
        if ( defaultMtg?.pmPools.length===0 ) {
            return "NOTDEFINED";
        }
        const ttPool = getComingTTPool();
        const curRaceNo = defaultMtg?.currentNumberOfRace;
        if ( !isStartSellpmPool(ttPool) && curRaceNo===0 )
            return "NOTSTART";
        else if (isStartSellpmPool(ttPool))
            return "STARTSELL";
        return "STOPSELL"
    }

    const isScratchedRunner = (st) => {
        return ["SCRATCHED", "SCRATCHEDRESERVED", "STANDBY"].includes(st?.toUpperCase())
    }

    const randomSels = () => {
        if ( getTTPoolStatus()!=="STARTSELL" ) {
            return;
        }

        const ttPool = getComingTTPool();
        setBets([...randomBet(defaultMtg, ttPool), ...randomBet(defaultMtg, ttPool)]);
        setBetAdded(0);
    }

    const randomBet = (mtg, ttPool) => {
        let bet = [];
        ttPool.leg.races.forEach(raceNo => {
            let race =  mtg.races.filter(x=> x.no===raceNo)[0];
            let size = race?.wageringFieldSize || 0;
            let legs = [];
            if ( race.runners.filter(x=> !isScratchedRunner(x.status)).length>=3 ) {
                legs = randomLeg(size, race.runners);
            }
            bet.push(legs);
        });
        return bet;
    }

    const randomLeg = (fieldSize, runners) => {
        let nums = [];
        while(nums.length < 3){
            const rand = Math.ceil(Math.random()*fieldSize)
            let runner = runners.filter(x=>parseInt(x.no)===rand)[0];
            if(runner && !nums.includes(rand) && !isScratchedRunner(runner.status)) nums.push(rand)
        }
        nums.sort((x,y) => {return x-y;});
        return nums;
    }

    const addTTQuickBet = () => {
        if(betAdded) return false
        const mtg = defaultMtg;
        const ttPool = getComingTTPool();

        let betObj = {};
        betObj.prod = 'HR';
        betObj.betType = "TT";
        betObj.venueid = mtg.venueCode;
        betObj.dayid = mtg.dateOfWeek;
        betObj.poolid = betObj.betType;
        betObj.raceno = ttPool.leg.races[0];
        betObj.noOfBets = 2;
        betObj.isRandom = true;
        betObj.useFlexiUi = false;
        betObj.isFlexi = false;
        betObj.unitBet = 10;
        betObj.betTotal = betObj.unitBet * betObj.noOfBets;

        betObj.bankers = [];
        betObj.meetingId = mtg.id;
        betObj.id = Date.now().toString();

        betObj.betList = bets.map((bet, idx)=>{
            let raceNo = ttPool.leg.races[idx % 3];
            let combs = bet.map(sel => {
                return mtg.races.find((race) => race.no == raceNo)?.runners.find((runner) => runner.no == sel);
            });
            return {
                raceNo: raceNo,
                betType: 'TT',
                bankers: [[], [], []],
                combs: combs}
        })

        if (addToBetSlip([betObj]).acceptResult) {
            if(isMobileSize){
                setBetAdded(1);
                updateOriginLocation(`.HRQuickPick .blockAddToSlip`,'HRCBall');
                setTimeout(() => {
                    setBetAdded(2);
                }, 700);
            }else{
                setBetAdded(2);
            }
        }
    };

    const ClickHereLink = ({lbl}) => {
        const {i18n} = useTranslation();
        let pLang = i18n.language==="en" ? "en-US" : "zh-HK";
        let url =  window.globalConfig.SPECIAL_URL + window.specialPages["SELLING_HOURS"].replace('{0}', pLang);
        return <span className="blockRacingLink" onClick={()=> NewWindow(url, '', 800, 600, 1, 1) }>{lbl}</span>
    }

    const ClickHereLinkInternal = ({lbl}) => {
        const {i18n} = useTranslation();
        const navigate = useNavigate();
        let pLang = i18n.language==="en" ? "en-US" : "zh-HK";
        return <span className="blockRacingLink" onClick={()=> navigate(`/${i18n.language}/racing`) }>{lbl}</span>
    }

    const NoTTMsg = () => {
        return <><div className="blockMsg2 width80per">
            <div style={{textAlign:'center'}}>{t('LB_RACE_INFO_NOTT')}</div>
        </div>
        <div></div>
        </>
    }

    const RacingEosMsg = () => {
        return <><div className="blockMsg2 width80per">
            <div style={{textAlign:'center'}}>{getRacingEosMesssage()}</div>
        </div>
        <div></div>
        </>
    }

    const TTNotStartMsg = () => {
        return <><div className="blockMsg2">
            <div>{t('LB_RACE_INFO_TT_NOTSTART1')}</div>
            <div>{t('LB_RACE_INFO_TT_NOTSTART2')}<ClickHereLink lbl={t('LB_RACE_INFO_TT_NOTSTART3')} />{t('LB_RACE_INFO_TT_NOTSTART4')}</div>
        </div>
        <div></div>
        </>
    }

    const TTStopSellMsg = () => {
        return <><div className="blockMsg2">
            <div>{t('LB_RACE_INFO_TT_STOPSELL1')}</div>
            <div>{t('LB_RACE_INFO_TT_STOPSELL2')}<ClickHereLinkInternal lbl={t('LB_CLICK_HERE')} />{t('LB_RACE_INFO_TT_STOPSELL3')}</div>
            <div>{t('LB_RACE_INFO_TT_STOPSELL4')}</div>
        </div>
        <div></div>
        </>
    }

    const BetAddMsg = () => {
        return <div className="blockMsg">
            <div>{t('LB_RACE_INFO_RETRY_MSG1')}</div>
            <div>{t('LB_RACE_INFO_RETRY_MSG2')}<ClickHereLinkInternal lbl={t('LB_CLICK_HERE')} />{t('LB_RACE_INFO_RETRY_MSG3')}</div>
            <div>{t('LB_RACE_INFO_RETRY_MSG4')}</div>
        </div>
    }

    const QuickPickTable = () => {
        const mtg = defaultMtg;
        if ( mtg==null ) {
            return <div className="blockBody blockBodyCenter" style={{}}>{t('LB_RACE_INFO_NOTT')}</div>
        }

        const showEosMessage = ["CLOSED"].includes(defaultMtg.status.toUpperCase()) && defaultMtg.isSeasonLastMeeting;
        const ttPoolStatus = getTTPoolStatus();
        return <div className="blockBody">
            <div className="blockTTQuickPickHead bottom-border">
                <div className='font-weight500'>{t('LB_RC_ODDS_TTQP')}</div>
                <div style={{wordBreak: 'keep-all'}}>{formatRacingDate(mtg.date)}<wbr />({capitalize(t('LB_' + mtg.dateOfWeek + '_SHORT'))})</div>
            </div>
            {showEosMessage && <RacingEosMsg />}
            {!showEosMessage && ttPoolStatus==="NOTDEFINED" && <NoTTMsg />}
            {!showEosMessage && ttPoolStatus==="NOTSTART" && <TTNotStartMsg />}
            {!showEosMessage && ttPoolStatus==="STOPSELL" && <TTStopSellMsg />}
            {!showEosMessage && ttPoolStatus==="STARTSELL" && <>
                <div className="blockQuickPickBody">
                    {betAdded === 2? <BetAddMsg />
                    :
                    <div className="blockTTTable">
                        <div>{t('LB_RC_ODDS_TT_1ST')}</div>
                        <div>{t('LB_RC_ODDS_TT_2ND')}</div>
                        <div>{t('LB_RC_ODDS_TT_3RD')}</div>
                        {bets.map(bet=>{
                            return <div className="right-border">{bet.join('+')}</div>
                        })}
                    </div>
                    }
                </div>
                <div className="blockButton">
                    <div className="blockRacingReset cta_hr btn" onClick={()=> { randomSels(); }}>{t('LB_RETRY')}</div>
                    {betAdded !== 2 && <div className="blockAddToSlip cta_yellow btn" onClick={()=> { addTTQuickBet(); glassboxCustomEvent("Add_to_Slip") }}>{t('LB_ADDTOSLIP')}</div>}
                </div>
            </>}
        </div>
    }

    return (
        <section className="HRQuickPick">

            {loading ? <EmptyTable/> : <QuickPickTable />}

        </section>
    );
}
export default EwinHomeTTQuickPick;