import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: window.location.pathname.split('/').includes('en') ? 'en' : 'ch',
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['translations'],
        backend: {
            /* translation file path */
            loadPath: `${process.env.PUBLIC_URL}/assets/i18n/translations/{{lng}}.json`
        },
        fallbackLng: 'en',
        debug: false,
        defaultNS: 'translations',
        keySeparator: false,
        preload:['en', 'ch'],
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
            defaultVariables: {
                WCIP_URL: window.globalConfig.WCIP_URL,
                JCRW_URL: window.globalConfig.JCRW_URL,
                JCEW_URL: window.globalConfig.JCEW_URL,
                JCFW_URL: window.globalConfig.JCFW_URL,
                JCBW_URL: window.globalConfig.JCBW_URL,
                SP_URL: window.globalConfig.SP_URL,
                IS_URL: window.globalConfig.IS_URL,
                M_URL: window.globalConfig.M_URL,
                CC_URL: window.globalConfig.CC_URL,
                RC_URL: window.globalConfig.RC_URL,
                MEMBER_URL: window.globalConfig.MEMBER_URL
            }
        },
        react: {
            wait: true
        }
    });

export default i18n;
