import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import OddsTable, { clipboardMeetingRow } from '../Common/RCOddsTableCompact';
import OddsDropLegend from '../Common/OddsDropLegend';
import RadioButtonSet from '../Common/RadioButtonSet';
import MobileTabSet from '../../Common/MobileTabSet';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import { ReplaceBetSelect } from '../Common/RacingBetline';
import { getComingleMsgs } from '../Common/Common';
import QINOddsTable from '../Common/QINOddsTable';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { RacingContext } from '../../Home/RacingPage';
import AddSlipBtn from '../Common/AddSlipBtn';
import { useWindowSize } from '../../Common/CommonHooks';
import {MobileCurrentOdds} from '../Common/CurrentOdds';
import Loading from '../../Common/ui-components/Loading';
import useCookie from '../../Common/hooks/useCookie';

const OddsWPQ = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    const { isMobileSize } = useWindowSize();
    const [betTypeStat, setBetTypeStat] = useState([
        { type: "QIN", name1: 'LB_QIN_S', disabled: false, checked: false },
        { type: "QPL", name1: 'LB_QPL_S', disabled: false, checked: false },
        { type: "QQP", name1: 'LB_QQP_S', disabled: false, checked: false }
    ]);
    const [betTypeValue, setBetTypeValue] = useCookie('WPQ_sel', "QIN", { expires: 365 * 2 });
    useEffect(() => {
        if (mtg != null) {
            let qinPool = mtg.poolInvs.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType=="QIN")[0];
            let qplPool = mtg.poolInvs.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType=="QPL")[0];
            betTypeStat[0].disabled = qinPool == null;
            betTypeStat[1].disabled = qplPool == null;
            betTypeStat[2].disabled = betTypeStat[0].disabled || betTypeStat[1].disabled;
            betTypeStat[0].checked = false;
            betTypeStat[1].checked = false;
            betTypeStat[2].checked = false;
            let curSelect = '';
            if(!betTypeStat[2].disabled && betTypeValue === betTypeStat[2].type) {
                betTypeStat[2].checked = true;
            } else if (!betTypeStat[1].disabled && betTypeValue === betTypeStat[1].type) {
                betTypeStat[1].checked = true;
            } else if (!betTypeStat[0].disabled) {
                betTypeStat[0].checked = true;
            } else if (!betTypeStat[1].disabled) {
                betTypeStat[1].checked = true;
            }
            setBetTypeStat([...betTypeStat]);
        }
    }, [context.content.raceNo])

    const RCColumns = {
        no: true,
        banker1: true,
        banker2: false,
        banker3: false,
        leg: true,
        horseName: true,
        rcNoteM: context.content.isPeNoteEnable,
        win: true,
        place: true,
        field: true
    };

    const RCColumnLbls = {
        banker1: t('LB_RC_ODDS_BANKER'),
        leg: t('LB_RC_ODDS_LEG')
    }

    const getSelectedBetType = () => {
        let tName = '';
        let tBetType = betTypeStat.filter(data => data.checked)[0];
        if (tBetType != null)
            tName = tBetType.type;
        return tName;
    }

    const handleWPQRadioClick = (value) => {
        // let value = event.target.getAttribute("value");
        setBetTypeValue(value)
        let oldType = betTypeStat.filter(d => d.checked)[0].type;
        for (let idx in betTypeStat) {
            if (betTypeStat.hasOwnProperty(idx)) {
                betTypeStat[idx].checked = false;
                if (betTypeStat[idx].type == value) {
                    betTypeStat[idx].checked = true;
                    ReplaceBetSelect(oldType, value);
                }
            }
        }
        setBetTypeStat([...betTypeStat]);
        context.racingFuncs.resetInvCal();
    }

    if (context == null) {
        return <Loading/>
    } else {
        const mtg = context.content.meeting;

        let tmpMsgs = getComingleMsgs(mtg, context.content.page, context.content.raceNo);
        let pageConfig = context.pageConfig;
        let comingleMsg1 = [];
        let comingleMsg2 = [];
        if (tmpMsgs != null) {
            let comingleLang = i18n.language == "en" ? "E" : "C";
            if ( tmpMsgs['NOTE1_' + comingleLang] != '' )
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE1_' + comingleLang]}</div>);
            if ( tmpMsgs['NOTE2_' + comingleLang] != '' )
                comingleMsg2.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
            if ( tmpMsgs['NOTE3_' + comingleLang] != '' )
                comingleMsg2.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}3: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
        }

        context.racingFuncs.copyClipboardRightOddsTable = () => {
            return clipboardMeetingRow({ context, t, i18n, raceNo: context.content.raceNo, columns: RCColumns });
        }

        const columnsCountBreakPoints = isMobileSize ? { 320: 1 } : { 350: 2 }
        return (
            <section className={context.content.page}>
                <InvestmentCalculator enable={true} />
                <MobileCurrentOdds />
                <table style={{ margin: "12px 0px", display:'block' }}>
                    <tr className="masonry-col" style={{display: 'flex', flexDirection: isMobileSize ? 'column' : null}}>
                        <td style={{ display:'flex', flexDirection: 'column' }}>
                            <QINOddsTable isLoading={props.isLoading} msg={comingleMsg1}/>
                            <div className='OddsDropLegend-Content-ge1280'><OddsDropLegend /></div>
                        </td>
                        <td style={{ display:'flex', flexDirection: 'column', width: isMobileSize ? '100%' : null }}>
                            <RadioButtonSet items={betTypeStat} handleRadioClick={handleWPQRadioClick} />
                            <MobileTabSet items={betTypeStat} handleRadioClick={handleWPQRadioClick} />
                            <OddsTable betType={context.content.page} columns={RCColumns} lbl={RCColumnLbls}
                                firstRace={context.content.raceNo} raceNo={context.content.raceNo}
                                selectedBetType={getSelectedBetType()} startCnt={1} showAddSlip={false} />
                            {comingleMsg2}
                        </td>
                    </tr>
                </table>
                <div className='OddsDropLegend-Content-lt1280'><OddsDropLegend /></div>
                <div className='AddSlipBtn-Content'><AddSlipBtn /></div>
                <InvestmentCalculator enable={!context.windowSize.isMobileSize} />
            </section>
        );
    }
}
export default OddsWPQ;