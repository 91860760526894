import { parseTopic } from '../../Football/Data/useFootballPush/parseTopic';
import { createPool } from '../../Football/Data/useFootballPush/createPool';
import moment from 'moment';
import { hasHideOdds, isDimBetLine, getBetLineCombSt, isRefundOrEliminated, allupCombCompare } from './common';
import { footballMatchStatusQuery } from './footballMatchAPI';

window.bsMatchinfoCache=[];

export  const parseFootballMessage = (topic, message, betBasketsRef) =>{
    let topicInfo = parseTopic(topic)
    betBasketsRef.current.forEach(betList => {
        betList.forEach(betLine =>{
            if(betLine.topics.includes(topic)){
                if(topicInfo.matchUpd){
                    updateBetLineMatch(betLine, message, topic)
                }else if(topicInfo.poolUpd || topicInfo.chpPooUpd){
                    updateBetLinePools(betLine, message, topic)
                }else if(topicInfo.touUpd){
                    updateBetLineTourn(betLine, message, topic)
                }
            }
        })
    })

}

const updateBetLinePools = (betLine, message, topic) => {
    const newPool = createPool(null, message, {isBetSlip: true})
    if(betLine.betObj.betType === 'ALUP'){
        updateAllUpPools(betLine, newPool, topic)
    }else {
        updateSinglePool(betLine, newPool, message)
    }
}

const updateSinglePool =async (betLine, newPool, message) =>{
    const oldCombObj = {...betLine.betObj.combs[0]}
    const oldPool = {...betLine.betObj.combs[0].currentPool}

    const newLine = newPool.lines.find(l => l.lineId ==  betLine.betObj.combs[0].lineId)
    const newCombination = newLine.combinations.find(c => c.combId == betLine.betObj.combs[0].combId)
    const isSameOdds = newCombination.currentOdds == oldCombObj.oddsVal

    if(['TQL','CHP'].includes(message.bTyp)){
        if(!message.dtl.tagMatchId ){
            betLine.betObj.matchInfo = null
            betLine.betObj.inplayDelay = false;
        }else if(message.dtl.tagMatchId){
            if(betLine.betObj.matchInfo?.id != message.dtl.tagMatchId){
                let newMatch= window.bsMatchinfoCache.find(item=>item.id===message.dtl.tagMatchId);
                if(!newMatch){
                    const bsMatchObj= await footballMatchStatusQuery([message.dtl.tagMatchId])
                    newMatch = bsMatchObj?.data?.matches?.[0]
                    if(newMatch){
                        window.bsMatchinfoCache.push(newMatch)
                    }
                }
                if(newMatch){
                    betLine.betObj.matchInfo = newMatch;
                    betLine.betObj.inplayDelay = newMatch.inplayDelay;
                }
            }
        }
    }
    // const isSelling = (betLine.betObj.matchInfo ?  betLine.betObj.matchInfo.isInteractiveServiceAvailable : betLine.betObj.tournInfo.isInteractiveServiceAvailable) && newPool.status === 'SELLINGSTARTED' && newLine.status == "AVAILABLE" && newCombination.status == "AVAILABLE"
    const isInteractiveServiceAvailable = betLine.betObj.tournInfo ?   betLine.betObj.tournInfo.isInteractiveServiceAvailable : betLine.betObj.matchInfo.isInteractiveServiceAvailable
    const isNormalStatus = newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
    let sellStatus= getBetLineCombSt({pool: newPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});

    let oddsUpdTs =  moment()
    betLine.betObj.combs.forEach(item =>{
        let oldSellStatus = item.sellStatus
        const isPreEvent = !betLine.betObj.matchInfo || betLine.betObj.matchInfo?.status == "PREEVENT";
        const hideOddsChanged = isDimBetLine(oldSellStatus) && !(oldSellStatus == 'SUSPENDED' && isPreEvent)
        item.currentPool = newPool
        item.showDash = isDimBetLine(sellStatus) && !(sellStatus == 'SUSPENDED' && isPreEvent)
        item.oldOdds = isSameOdds ? item.oldOdds :  oldCombObj.oddsVal
        item.oddsVal = isSameOdds ? item.oddsVal :  newCombination.currentOdds
        item.oddsUpdTs = hideOddsChanged ? null :  isSameOdds ? item.oddsUpdTs : oddsUpdTs
        item.sellStatus = isRefundOrEliminated(sellStatus, newCombination, newPool.status) || sellStatus
        item.isStopSell = isDimBetLine(sellStatus)
    })
    betLine.betObj.sellStatus = sellStatus
    betLine.betObj.isStopSell = isDimBetLine(sellStatus)
}

const updateAllUpPools = (betLine, newPool, topic) =>{
    const topicInfo = parseTopic(topic)
    const isTournamentBet = window.fbConfig?.['TournamentAllupPools'].some(i => betLine.id.includes(i))
    const matchId = isTournamentBet ? topicInfo.tournamentid : topicInfo.matchid
    let betLineSellStatus = 'SELLING'
    betLine.betObj.betList.forEach(betItem => {
        const isPreEvent = !betItem.matchInfo || betItem.matchInfo?.status == "PREEVENT";
        betItem.combs.forEach(item =>{

                if(betItem.matchId ==  matchId && item.poolId === topicInfo.poolid) {
                    const newLine = newPool.lines.find(l => l.lineId ==  item.lineId)
                    const newCombination = newLine.combinations.find(c => c.combId == item.combId)
                    const isSameOdds = newCombination.currentOdds == item.oddsVal
                    const isInteractiveServiceAvailable = betItem.tournInfo ?  betItem.tournInfo.isInteractiveServiceAvailable : betItem.matchInfo.isInteractiveServiceAvailable
                    const isNormalStatus =  newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
                    let sellStatus=  getBetLineCombSt({pool: newPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});

                    let oddsUpdTs =  moment()
                    let oldSellStatus = item.sellStatus
                    const hideOddsChanged = isDimBetLine(oldSellStatus) &&  !(oldSellStatus == 'SUSPENDED' &&  isPreEvent)
                    if(sellStatus != 'SELLING' ) betLineSellStatus = betLineSellStatus == 'CLOSED' ? betLineSellStatus : sellStatus
                    item.currentPool = newPool
                    item.showDash = isDimBetLine(sellStatus) && !(sellStatus == 'SUSPENDED' &&  isPreEvent)
                    item.oldOdds = isSameOdds ? item.oldOdds :  item.oddsVal
                    item.oddsVal = isSameOdds ? item.oddsVal :  newCombination.currentOdds
                    item.oddsUpdTs = hideOddsChanged ? null :  isSameOdds ? item.oddsUpdTs : oddsUpdTs
                    item.sellStatus = isRefundOrEliminated(sellStatus, newCombination, newPool.status) || sellStatus
                    item.isStopSell = isDimBetLine(sellStatus)
                }else{
                    const newLine = item.currentPool.lines.find(l => l.lineId ==  item.lineId)
                    const newCombination = newLine.combinations.find(c => c.combId == item.combId)
                    const isInteractiveServiceAvailable = betItem.tournInfo ?  betItem.tournInfo.isInteractiveServiceAvailable : betItem.matchInfo.isInteractiveServiceAvailable
                    const isNormalStatus =  newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
                    let sellStatus =  getBetLineCombSt({pool: item.currentPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});
                    if(sellStatus != 'SELLING' ) betLineSellStatus = betLineSellStatus == 'CLOSED' ? betLineSellStatus : sellStatus
                    item.sellStatus = isRefundOrEliminated(sellStatus, newCombination, item.currentPool.status) || sellStatus
                    item.isStopSell = isDimBetLine(sellStatus)
                }
            })
    })
    betLine.betObj.sellStatus =  betLineSellStatus
    betLine.betObj.isStopSell = isDimBetLine(betLineSellStatus)

}

const updateBetLineMatch = (betLine, message, topic) =>{
    if(betLine.betObj.betType === 'ALUP'){
        updateAllUpMatch(betLine, message, topic)
    }else {
        updateSingleMatch(betLine, message)
    }
}

const updateAllUpMatch = (betLine, message, topic) =>{
    let inplayDelay = false
    let topicInfo = parseTopic(topic)
    let matchId = topicInfo.matchid
    let isInteractiveServiceAvailable = true
    let betLineSellStatus = 'SELLING'
    if(message.chlInfo){
        isInteractiveServiceAvailable =  message.chlInfo.find(x=> x.chl == "InteractiveService").st == "Available";
    }
    betLine.betObj.betList.forEach(betItem => {
        if(betItem.matchId ==  matchId){
            let curInplayDelay = message.ref?.inplayDelay
            betItem.matchInfo = {
                ...betItem.matchInfo,
                inplayDelay: curInplayDelay,
                status : message.st.toUpperCase(),
                sequence: message.seq,
                updateAt : message.stUpdAt,
                isInteractiveServiceAvailable
            }
        }
        betItem.combs.forEach(item =>{
                const newLine = item.currentPool.lines.find(l => l.lineId ==  item.lineId)
                const newCombination = newLine.combinations.find(c => c.combId == item.combId)
                const isInteractiveServiceAvailable =  betItem.matchInfo.isInteractiveServiceAvailable
                const isNormalStatus =  newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
                let sellStatus =  getBetLineCombSt({pool: item.currentPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});
                if(sellStatus != 'SELLING' ) betLineSellStatus = betLineSellStatus == 'CLOSED' ? betLineSellStatus : sellStatus
                item.sellStatus = isRefundOrEliminated(sellStatus, newCombination, item.currentPool.status) || sellStatus
                item.isStopSell = isDimBetLine(sellStatus)
        })
        inplayDelay = inplayDelay || betItem.matchInfo.inplayDelay
    })
    betLine.betObj.inplayDelay = inplayDelay
    betLine.betObj.sellStatus =  betLineSellStatus
    betLine.betObj.isStopSell = isDimBetLine(betLineSellStatus)
}

const updateSingleMatch = (betLine, message) =>{
    let inplayDelay = message.ref?.inplayDelay
    betLine.betObj.inplayDelay = inplayDelay
    let betLineSellStatus = 'SELLING'
    let isInteractiveServiceAvailable = true
    if(message.chlInfo){
        isInteractiveServiceAvailable =  message.chlInfo.find(x=> x.chl == "InteractiveService").st == "Available";
    }
    betLine.betObj.matchInfo = {
        ...betLine.betObj.matchInfo,
        inplayDelay,
        status : message.st.toUpperCase(),
        sequence: message.seq,
        updateAt : message.stUpdAt,
        isInteractiveServiceAvailable
    }
    betLine.betObj.combs.forEach(item =>{
        const newLine = item.currentPool.lines.find(l => l.lineId ==  item.lineId)
        const newCombination = newLine.combinations.find(c => c.combId == item.combId)
        const isInteractiveServiceAvailable = betLine.betObj.tournInfo ?   betLine.betObj.tournInfo.isInteractiveServiceAvailable : betLine.betObj.matchInfo.isInteractiveServiceAvailable
        const isNormalStatus =  newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
        let sellStatus =  getBetLineCombSt({pool: item.currentPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});
        if(sellStatus != 'SELLING' ) betLineSellStatus = betLineSellStatus == 'CLOSED' ? betLineSellStatus : sellStatus
        item.sellStatus = isRefundOrEliminated(sellStatus, newCombination, item.currentPool.status) || sellStatus
        item.isStopSell = isDimBetLine(sellStatus)
    })
     betLine.betObj.sellStatus =  betLineSellStatus
    betLine.betObj.isStopSell = isDimBetLine(betLineSellStatus)
}

const updateBetLineTourn = (betLine, message, topic) => { 
    let betLineSellStatus = 'SELLING'
    let isInteractiveServiceAvailable = true
    if(message.chlInfo){
        isInteractiveServiceAvailable =  message.chlInfo.find(x=> x.chl == "InteractiveService").st == "Available";
    }
    if(betLine.betObj.betType == 'ALUP'){
        betLine.betObj.betList[0].tournInfo = {
            ...betLine.betObj.betList[0].tournInfo,
            status : message.st.toUpperCase(),
            isInteractiveServiceAvailable
        }
        betLine.betObj.betList[0].combs.forEach(item =>{
            const newLine = item.currentPool.lines.find(l => l.lineId ==  item.lineId)
            const newCombination = newLine.combinations.find(c => c.combId == item.combId)
            const isInteractiveServiceAvailable = betLine.betObj.betList?.[0]?.tournInfo?.isInteractiveServiceAvailable
            const isNormalStatus =  newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
            let sellStatus =  getBetLineCombSt({pool: item.currentPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});
            if(sellStatus != 'SELLING' ) betLineSellStatus = betLineSellStatus == 'CLOSED' ? betLineSellStatus : sellStatus
            item.sellStatus = isRefundOrEliminated(sellStatus, newCombination, item.currentPool.status) || sellStatus
            item.isStopSell = isDimBetLine(sellStatus)
        })
    }else{
        betLine.betObj.tournInfo = {
            ...betLine.betObj.tournInfo,
            status : message.st.toUpperCase(),
            isInteractiveServiceAvailable
        }
    
        betLine.betObj.combs.forEach(item =>{
            const newLine = item.currentPool.lines.find(l => l.lineId ==  item.lineId)
            const newCombination = newLine.combinations.find(c => c.combId == item.combId)
            const isInteractiveServiceAvailable = betLine.betObj.tournInfo ?   betLine.betObj.tournInfo.isInteractiveServiceAvailable : betLine.betObj.matchInfo.isInteractiveServiceAvailable
            const isNormalStatus =  newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
            let sellStatus =  getBetLineCombSt({pool: item.currentPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});
            if(sellStatus != 'SELLING' ) betLineSellStatus = betLineSellStatus == 'CLOSED' ? betLineSellStatus : sellStatus
            item.sellStatus = isRefundOrEliminated(sellStatus, newCombination, item.currentPool.status) || sellStatus
            item.isStopSell = isDimBetLine(sellStatus)
        })
    }

     betLine.betObj.sellStatus =  betLineSellStatus
    betLine.betObj.isStopSell = isDimBetLine(betLineSellStatus)
}