import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isAllUpPage, isLastRaceRan, isPoolDefined, isRacingFOPage, isLiveRacingWhitelisted } from './Common';
import { isLocalMeeting, handleClickRacingLive } from '../../Common/home-common';
import { GetRaceConfig } from '../../Common/ConfigHelper';
import { RCMeetingSlider, VenueSlider } from '../../Common/Slider';
import { RacingContext } from '../../Home/RacingPage';
import { useWindowSize } from '../../Common/CommonHooks';
import moment from 'moment';

function RaceNo(props) {
    const { isMobileSize } = useWindowSize();
    const context = useContext(RacingContext);
    const { t, i18n } = useTranslation();

    let mtg = context.content.meeting;
    if (mtg==null)
        return null;

    let item = [];
    let maxAllUp = 6;

    let alupData = context.content.alupData;
    let betType = context.content.page;
    let lastRaceRan = isLastRaceRan(mtg);

    for (let i=0; i<alupData?.length; i++) {
        maxAllUp = Math.min(maxAllUp, alupData[i].maxLeg);
    }

    let maxRace = Math.max(...mtg.races.map(x=>x.no));
    let ranRace = Math.max(...mtg.races.filter(x=> ["RESULT", 'VOIDED', 'CLOSED', 'ABANDONED'].includes(x.status.toUpperCase())).map(x=>x.no));
    for (let i = 1; i <= maxRace; i++) {
        const currentRace = mtg.races?.find(race => race.no === i )
        if ( !currentRace ) {
            continue;
        }

        let pDef = isPoolDefined(betType, i, mtg.poolInvs);
        let _active = "";
        let _dimmed = "";
        const isFinishedRace =  ranRace >= i
        const isTurf =  currentRace?.raceTrack?.description_en?.toUpperCase() === 'TURF';
        if (isAllUpPage(betType)) {
            let isExistNo = alupData.filter(x => x.raceNo == i).length > 0;
            if (!pDef)
                _dimmed += " dimmed disabled";
            else if ( lastRaceRan && i<maxRace  ) {
                _dimmed += " dimmed  disabled";
                pDef = false;
            }
            else if ( isFinishedRace || (alupData.length >= maxAllUp && !isExistNo && pDef)) {
                _dimmed += " dimmed  disabled";
                pDef = false;
                if(lastRaceRan && i == ranRace){
                    _dimmed = 'dimmed active'
                }
            } else {
                _active = isExistNo ? " active" : _active;
            }
        } else {
            if (!pDef){
                _dimmed += "disabled dimmed";
            }
            else if (context.content.raceNo === i){
                _active = " active";
                if(isFinishedRace && ["RESULT", 'VOIDED', 'CLOSED', 'ABANDONED'].includes(currentRace.status.toUpperCase())){
                    _active = " active dimmed";
                }
            }else if (isFinishedRace && ["RESULT", 'VOIDED', 'CLOSED', 'ABANDONED'].includes(currentRace.status.toUpperCase())){
                _active = " dimmed";
            }
        }

        item.push(pDef ?
            <div role="button" aria-label={t('LB_AS_RACE_X').replace('X', i)} id={`raceno_${i}`} className={`race-no-item ${_active} ${isTurf ? 'isTurf' : ''}`} key={`metting-raceno-li-${i}`} onClick={() => props.handleRaceNoClick(context, i)}>
                <h3><span className={_dimmed}>{i}</span></h3>
            </div> :
            <div role="button" aria-label={t('LB_AS_RACE_X').replace('X', i)} id={`raceno_${i}`} className={`race-no-item ${_dimmed} ${isTurf ? 'isTurf' : ''}`} key={`metting-raceno-li-${i}`}>
                <h3 style={{ cursor: "auto" }}><span className={_dimmed}>{i}</span></h3>
            </div>);
    }

    //return raceNo;
    return <RCMeetingSlider className={'raceNoSlider'} isMobileSize={isMobileSize} venue={context?.content?.venue} slideIndex={context?.content?.raceNo} >{item}</RCMeetingSlider>;
}

const venusMap = {
    "谷":"快活谷",
    "HV":"Happy Valley",
    "田":"沙田",
    "ST":"Sha Tin",
    "S1":"S1",
    "S2":"S2",
    "S3":"S3",
    "S4":"S4",
    "S5":"S5",
    "S6":"S6",
    "S7":"S7",
}

function Venue(props) {
    const { isMobileSize } = useWindowSize();
    const {t} = useTranslation()
    let venue = [];
    let item = [];
    const context = useContext(RacingContext);
    const mtgSize = context.content.meetingList.length;
    const meetingList = context.content.meetingList.sort(sortMeeting);
    // const meetings =  Array.isArray(context.content.meeting) ? context.content.meeting : [context.content.meeting]
    // const meetingList = context.content.meetingList.map((m) => {
    //     const foundMeeting = meetings.find((mt) => mt.id === m.id)
    //     if(foundMeeting) {
    //         const currentNumberOfRace = foundMeeting.currentNumberOfRace
    //         const foundRace = foundMeeting.races.find((r) => r.no === currentNumberOfRace)
    //         m.postTime =  foundRace ? foundRace.postTime : ''
    //     } else {
    //         m.postTime = ''
    //     }
    //     return m
    // }).sort(sortMeeting);

    for (let i = 0; i < mtgSize; i++) {
        let _mtg = meetingList[i];
        let _active = "";
        let _dimmed = "";
        if (context.content.meeting?.id === _mtg.id)
            _active = "active";
        // if (_mtg.status == "CLOSED" || _mtg.status.indexOf("ABANDON")>=0)
        //     _dimmed = "dimmed";
        const venueName = isLocalMeeting(_mtg.venueCode) ? props.t('LB_RACE_' + _mtg.venueCode) : _mtg.venueCode;
        const isDuebleHeader = isLocalMeeting(_mtg.venueCode) && (meetingList.filter(item => item.venueCode== _mtg.venueCode).length > 1)
        const weekDay = moment(_mtg.date).utcOffset('+08:00').weekday()
        item.push(
            <div  role="button" aria-label={venusMap[venueName] ?? venueName} id={`venue_${ _mtg.venueCode}`}  className={`${_active} ${_dimmed}`} key={`metting-venue-li-${_mtg.id}`} onClick={() => props.handleVenueClick(_mtg.date, _mtg.venueCode)}>
                <h3><span >{venueName}</span></h3>
                {isDuebleHeader ? <div className='dhWeekDay'>{t(`LB_WEEKDAY_SHORT_${weekDay}`)}</div> : null}
            </div>);
    }

    return <VenueSlider isMobileSize={isMobileSize} mtgSize={mtgSize} page={props.page}>{item}</VenueSlider>;

}

export const sortMeeting = (x, y) => {
    if ( x.date != y.date ) {
        return x.date < y.date ? -1 : 1;
    }
    const xPostTime=x.races[0]?.postTime;
    const yPostTime=y.races[0]?.postTime;
    if(xPostTime && yPostTime && (xPostTime != yPostTime)) {
        return new Date(xPostTime).getTime() < new Date(yPostTime).getTime() ? -1 : 1;
    }

    const validVenue= GetRaceConfig()?.validVenue || [];
    return validVenue.indexOf(x.venueCode) - validVenue.indexOf(y.venueCode);
}

const MeetingButton = (props) => {

    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtgSize = context.content.meetingList.length;
    const hideButton =  (isRacingFOPage(context.content.page) || "JTCOMBO"===context.content.page) && mtgSize<2;
    let rcConfig = context.pageConfig;
    const liveracing_whitelisted = isLiveRacingWhitelisted();
    const pCardTier =  window.globalConfig.IS_IB? sessionStorage.getItem('PCardTier') : sessionStorage.getItem('login_priorityCardTier');
    const showELVAIcon =  rcConfig?.showLiveRacingBtn && context.content.isLogon && liveracing_whitelisted && (pCardTier ||  window.globalConfig.IS_SPEEDBET)

    if (!rcConfig.hideMtgBtn && !hideButton) {
        let meetingBtnCss = context.content.meetingList.length > 4 ? 'slide' : 'slide' + context.content.meetingList.length;

        return (<>
            <div className={`meeting-button ${meetingBtnCss}`} >
                {
                    !rcConfig?.hideVenueBtn && <Venue {...props} t={t} />
                }
                {
                    !rcConfig.hideMtgBtn && !rcConfig?.hideRaceNoBtn && <>
                        <div className='meetingBtnSplitLine'></div>
                        <RaceNo {...props} />
                    </>
                }
                {
                    showELVAIcon &&
                    <>
                        <div className="separator live-icon" />
                        <div className="live-racing-icon cursor-pointer" onClick={()=> { handleClickRacingLive(i18n.language) }}></div>
                    </>
                }
            </div>
        </>)
    } else {
        return null;
    }


}
export default MeetingButton;
