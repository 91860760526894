import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import CheckBox from '../../Common/ui-components/CheckBox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { useWindowSize } from '../../Common/CommonHooks';

const AllupBetTypeBox = ({ selectedOddsType, handleClickOddsType, isAllUpEnabledWithBetType }) => {

    const context = useContext(FootballContext);
    const { t } = useTranslation();

    const mixAllupPools = context.content.config.MixAllupPools;
    const sixLegPools = context.content.config.MixAllup6LegPools;
    const { isMobileSize } = useWindowSize();
    const [direction ,setDirection] = useState(() => isMobileSize ? 'down' : 'up')
    const onArrowIconClick = () => {
        if(direction === 'up') {
            setDirection('down')
        } else {
            setDirection('up')
        }
    }

    return <div>
        { isMobileSize && <div className='bettype-toggle' onClick={onArrowIconClick}>
            <span className='selected-betType-text'>{t('LB_FB_SELECT_BET_TYPE')}</span>
            <span className='toggle-icon'>
                <ArrowIcon direction={direction} size={30}/>
            </span>
        </div>}
        <div className='content' style={{display: direction==='up' ? '' : 'none'}}>
            <div className="headLbl">{t('LB_FB_SELECT_ALL_ODDS')}</div>
            { isMobileSize && <div className='quick-select'>
                <span onClick={() => handleClickOddsType('clear')}>{t('LB_FB_CLEAR_ALL')}</span>
                <span onClick={() => handleClickOddsType('all')}>{t('LB_FB_SELECT_ALL')}</span>
            </div> }
            <div className='odds-table'>
                {mixAllupPools.map((_oddsType) => {
                    if(!isAllUpEnabledWithBetType(_oddsType)) return null
                    let isChecked = selectedOddsType.includes(_oddsType);
                    let leg = sixLegPools.includes(_oddsType) ? '[' + t('LB_FB_LEGS_6') + t('LB_FB_LEGS') + ']' : ''

                    return <div key={_oddsType}>
                        <CheckBox product='football' noBorder onClick={() => handleClickOddsType(_oddsType)} defaultChecked={isChecked} checked={isChecked} />
                        <div onClick={() => handleClickOddsType(_oddsType)}>{t('LB_FB_TITLE_' + _oddsType)}{leg}</div>
                    </div>
                })}
            </div>
        </div>
    </div>

}
export default AllupBetTypeBox;