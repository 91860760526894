import moment from 'moment';
import { isInDisplayCriteria, addOddsDiff, matchCompare, updateAllUpCalCookies, diffAllUpSelection, checkINPLAYandOFMPool } from './common';
import { createPool } from './createPool';
import { getQueryVariables } from '../../Data/DataQuery';
import { getDisplayMatches } from '../useGetMatchList/common';
import { trimTournament, getQueryTorunIds, getQueryMatchIdForDateTab, getQueryDateRange, isTournamentPage } from '../../Common/Common';

export const parseUpdMatchPool = (message, topicInfo, contentRef, loadMatchQuery,option={}) => {
    let pool = null;
    let topicMatchID = topicInfo.matchid;
    let topicPoolID =topicInfo.poolid;
    let isUntag = false
    const isAllOddsPage = contentRef.current.config.AllOddsPages.includes(contentRef.current.page)
    const isTournPage = isTournamentPage(contentRef.current.page)
    if(message.dtl?.tagMatchId){
        topicMatchID = message.dtl.tagMatchId;
    }
    if(['TQL', 'CHP'].includes(message.bTyp) && !topicMatchID){
        if(isAllOddsPage){
            if(contentRef.current.matchData?.matches?.[0]?.foPools?.find(pool => pool.id === message.id) != null){
                const match =  contentRef.current.matchData?.matches?.[0]
                topicMatchID = match.id
                if(!message.dtl.tagMatchId){
                    isUntag = true
                }

            }
        }else{
            topicMatchID =  getUntagMatchId(contentRef.current.listData.matches, message, topicInfo)
            if(topicMatchID){
                isUntag = true
            }
        }

    }
    updateAllUpCalCookies(message, createPool(contentRef, message))
    let listDataMatch = contentRef.current.rawMatchList.find(x => x.id===topicMatchID);
    if(listDataMatch) {  
        updatePoolInfo(listDataMatch, message, {...option, isUntag});
    }

    let match = contentRef.current.matchData?.matches.find(x => x.id===topicMatchID);
    if(match) {  
        updatePoolInfo(match, message, {...option, isUntag});
        if ( listDataMatch ) {
            listDataMatch.poolInfo = {...match.poolInfo};
        }
    }
    let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
    contentRef.current.listData = { matches : matchList };
    diffAllUpSelection(contentRef);

    updateMatchList(contentRef, match);
    if(contentRef.current.matchData) {
        if(match){  
            if(!match.foPools)  match.foPools = []
            pool = match.foPools.find(x => x.id == topicPoolID);
            if(pool){
                updatePoolDetail(contentRef, match, pool, message,  {...option, isUntag});
            }
        }

        if ( ["SellingStarted", "SellingStopped", "Hold"].includes(message.st) && !isUntag) {
            if ( isInDisplayCriteria(listDataMatch, message.bTyp, message.dtl?.betOffer?.inplay, contentRef) && contentRef.current.listData.matches.some(m => m.id === topicMatchID)) {
                if ( !match && listDataMatch ) {
                    match = {...listDataMatch};
                    match.foPools = [];
                    contentRef.current.matchData.matches.push(match);
                    contentRef.current.matchData.matches.sort(matchCompare);
                }
                if ( match && !pool ) {
                    let newPool = createPool(contentRef, message);
                    if(newPool.lines.length != 0 && checkINPLAYandOFMPool(message, contentRef)){
                        match.foPools.push(newPool);
                    }
                }
            }
        }
    }

    if(["TOURN"].includes(contentRef.current.selection.type) && !option.esReplay && !isTournPage) {
        contentRef.current.selection.tabs = trimTournament(contentRef.current.listData.matches);
        const isEmptyTourn = !contentRef.current?.listData?.matches.some(match => contentRef.current.selection.value.includes(match.tournament.id))
        const newTournIds = getQueryTorunIds(contentRef.current.listData.matches, contentRef.current, null)
        if( contentRef.current?.matchData?.matches?.length === 0 && loadMatchQuery  ){
            if(isEmptyTourn){
                loadMatchQuery({ page: contentRef.current.page, tournIds: newTournIds }, null, {disabledMsgCache: true})
            }
        }
    }
    if(['DATE'].includes(contentRef.current.selection.type) && !option.esReplay){
        let oldDate = contentRef.current.selection.value[0]
        if(isAllOddsPage){
            getQueryDateRange(contentRef.current.listData.matches, contentRef.current.offset, contentRef.current)
            getQueryMatchIdForDateTab(contentRef.current.listData.matches, contentRef.current.offset, contentRef.current, contentRef.current?.matchData?.matches[0].id)

        }else{
            let [dateFrom, dateTo] = getQueryDateRange(contentRef.current.listData.matches, contentRef.current.offset, contentRef.current)
            if(oldDate != contentRef.current.selection.value[0]){
                loadMatchQuery({ page: contentRef.current.page, dateFrom: dateFrom, dateTo: dateTo }, null, {disabledMsgCache: true})
            }
        }
    }
}

const updatePoolDetail = (contentRef,match,pool,message, option) => {
    const {isUntag} =option
    if(!contentRef.current.isESContent && (message.st == "Suspended" || isRefund(message.st) || isUntag)){
        match.foPools = match.foPools.filter(x=> x.id!=message.id);
        updateCheckBoxSelection(contentRef, message.id)
    }
    else {
        let idx = match.foPools.findIndex(x=>x.id===pool.id);
        let newPool = createPool(contentRef, message);
        let availableLines = newPool.lines.filter(line => line.status?.toUpperCase() === 'AVAILABLE')
        if(availableLines.length === 0 && !contentRef.current.isESContent){
            addOddsDiff(contentRef, match, pool, newPool, option);
            match.foPools = match.foPools.filter(x=> x.id!=message.id)
        }else{
            addOddsDiff(contentRef, match, pool, newPool, option);
            match.foPools[idx] = newPool;
        }
    }
}

export const updatePoolInfo = (match, message, option) => {
    let betType = message.bTyp,
        poolStatus = message.st,
        inplay = message.dtl?.betOffer?.inplay,
        lines =  message.lines?.line,
        instNo = message.instNo;
    if(instNo>0){
        betType = betType+instNo
    }
    const isUntag =option?.isUntag
    if(isUntag){
        match.poolInfo.normalPools = match.poolInfo.normalPools.filter(x => x != betType);
        match.poolInfo.inplayPools = match.poolInfo.inplayPools.filter(x => x != betType);
        match.poolInfo.sellingPools = match.poolInfo.sellingPools.filter(x => x != betType);
        match.poolInfo.definedPools = match.poolInfo.definedPools.filter(x => x != betType);
        return
    }
    switch (poolStatus) {
        case 'Suspended':
        case 'PayoutStarted':
        case 'PayoutStopped':
        case 'PayoutHold':
        case 'Refund':
        case 'RefundBeforeSuspend':
            // for multi instance pools checking
            let pools= match.foPools?.filter(x=> x.oddsType==betType && instNo &&  x.instNo!=instNo) || [];
            if ( pools.length==0 ) {
                match.poolInfo.normalPools = match.poolInfo.normalPools?.filter(x => x != betType);
                match.poolInfo.inplayPools = match.poolInfo.inplayPools?.filter(x => x != betType);
                match.poolInfo.sellingPools = match.poolInfo.sellingPools?.filter(x => x != betType);
            }
            break;
        case 'SellingStarted':
            if(lines.filter(line => line.st=== 'Available' ).length == 0){
                match.poolInfo.normalPools = match.poolInfo.normalPools.filter(x => x != betType);
                match.poolInfo.inplayPools = match.poolInfo.inplayPools.filter(x => x != betType);
                match.poolInfo.sellingPools = match.poolInfo.sellingPools.filter(x => x != betType);
            }else{
                match.poolInfo.normalPools.push(betType);
                match.poolInfo.normalPools = [...new Set(match.poolInfo.normalPools)];
                if ( inplay ) {
                    match.poolInfo.inplayPools.push(betType);
                    match.poolInfo.inplayPools = [...new Set(match.poolInfo.inplayPools)];
                }else{
                    match.poolInfo.inplayPools = match.poolInfo.inplayPools.filter(btype => btype != betType )
                }
                match.poolInfo.sellingPools.push(betType);
                match.poolInfo.sellingPools = [...new Set(match.poolInfo.sellingPools)];
                if(match.poolInfo.definedPools){
                    match.poolInfo.definedPools.push(betType);
                    match.poolInfo.definedPools = [...new Set(match.poolInfo.definedPools)];
                }
            }
            break;
        case 'SellingStopped':
        case 'Hold':
            if(lines.filter(line => line.st=== 'Available' ).length == 0){
                match.poolInfo.normalPools = match.poolInfo.normalPools?.filter(x => x != betType);
                match.poolInfo.inplayPools = match.poolInfo.inplayPools?.filter(x => x != betType);
                match.poolInfo.sellingPools = match.poolInfo.sellingPools?.filter(x => x != betType);
            }else{
                match.poolInfo.normalPools?.push(betType);
                match.poolInfo.normalPools = [...new Set(match.poolInfo.normalPools)];
                if ( inplay ) {
                    match.poolInfo.inplayPools.push(betType);
                    match.poolInfo.inplayPools = [...new Set(match.poolInfo.inplayPools)];
                }else{
                    match.poolInfo.inplayPools = match.poolInfo.inplayPools.filter(btype => btype != betType )
                }
                match.poolInfo.sellingPools = match.poolInfo.sellingPools.filter(x => x != betType);
            }
            break;
    }

    updateScoringInfo(match, message);
}

const updateMatchList = (contentRef, match) => {
    if (!match || contentRef.current.isESContent) {
        return;
    }

    let removeMatch = false;
    switch ( contentRef.current.page ) {
        case "INPLAY":
            removeMatch = match.poolInfo.inplayPools.length==0;
            break;
        case "ALLODDS":
        case "INPLAY_ALL":
        case "CASHOUT":
            removeMatch = false
            break
        case "OFM":
        case "MIXALLUP":
        case "MIXALLUP_DETAIL":
            removeMatch = match.poolInfo.normalPools.length==0;
            break;
        case "HOME":
        case "HAD":
            removeMatch = match.poolInfo.normalPools.length==0 || (!match.poolInfo.definedPools.includes("HAD") && !match.poolInfo.definedPools.includes("EHA"));
            break;
        case "HDC":
            removeMatch = match.poolInfo.normalPools.length==0 || (!match.poolInfo.definedPools.includes("HDC") && !match.poolInfo.definedPools.includes("EDC"));
            break;
        case "HIL":
            removeMatch = match.poolInfo.normalPools.length==0 || (!match.poolInfo.definedPools.includes("HIL") && !match.poolInfo.definedPools.includes("EHL"));
            break;
        case "CHL":
            removeMatch = match.poolInfo.normalPools.length==0 || (!match.poolInfo.definedPools.includes("CHL") && !match.poolInfo.definedPools.includes("ECH"));
            break;
        case "CRS":
            removeMatch =  match.poolInfo.normalPools.length==0 || (!match.poolInfo.definedPools.includes("CRS") && !match.poolInfo.definedPools.includes("ECS"));
            break;
        case "TTG":
            removeMatch = match.poolInfo.normalPools.length==0 || (!match.poolInfo.definedPools.includes("TTG") && !match.poolInfo.definedPools.includes("ETG"));
            break;
        default:
            removeMatch = match.poolInfo.normalPools.length==0 || !match.poolInfo.definedPools.some(betType => betType.includes(contentRef.current.page));
            break;
    }
    if (removeMatch) {
        if ( contentRef.current.matchData ) {
            contentRef.current.matchData.matches = contentRef.current.matchData.matches.filter(x => x.id!=match.id);
        }
    }
}

const isRefund = (status) =>{
    return ['PayoutStarted', 'PayoutStopped', 'PayoutHold', 'Refund', 'RefundBeforeSuspend'].includes(status)
}

const updateCheckBoxSelection = (contentRef, id,) =>{
    contentRef.current.oddsSelection.forEach((item)=>{
        const [betType, lineId, combId, poolId, str ]  = item.id.split('_')
            if(poolId === id){
                item.checked = false
            }
    })
}

const updateScoringInfo = (match, message) => {
    if ( !["NTS", "ENT"].includes(message.bTyp) )
        return;
    
    let ntsInfoName = "NTS"===message.bTyp ? "ntsInfo" : "entInfo";
    match.poolInfo[ntsInfoName] = match.poolInfo[ntsInfoName].filter(x=> x.indexOf(message.instNo + ":") < 0);
    if ( message.st.indexOf("Refund")>=0 ) {
        match.poolInfo[ntsInfoName].push(message.instNo + ":RFD");
    }
    else if ( message.st.indexOf("PayoutStarted")>=0 ) {
        let winComb = message.lines?.line?.[0]?.comb?.filter(x=> x.st.toUpperCase()==="WIN")[0];
        if ( winComb ) {
            match.poolInfo[ntsInfoName].push(message.instNo + ":" + winComb.str);
        }
    }
}

const getUntagMatchId = (matches, message, topicInfo) => {
    let betType = message.bTyp,
        instNo = message.instNo;
    if(instNo>0){
        betType = betType+instNo
    }
    let tournId = topicInfo.tournamentid
    let match = matches.find(item =>item.tournament.id === tournId && item.poolInfo.normalPools.includes(betType) )
    return match?.id
}