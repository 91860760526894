import React,{ useContext, useEffect, useRef, useState } from "react"

import { GlobalContext } from '../../../contexts/GlobalContext';
import { MyBetSlipContext } from '../BetSlipHooks'
import { Query, apiErrorCallback } from '../../Common/graphqlFetch'
import { getMatchQuery, getQueryVariables, getTournamentQuery } from '../../Football/Data/DataQuery';
import { getFoPoolQuery } from '../../Racing/Data/DataQuery';
import { setMatchQueryDataToBetSlip, setTournQueryDataToBetSlip } from './updateFBData';
import { setRCFODatatoBetSlip } from './updateRCData';
import { getQueryId } from './common'

const useBSDataRefresh = ({betBasketsRef,computePanelAllupFunc}) =>{
    const {
        betBaskets,
        setBetBaskets,
        currentBasket,
        setCurrentBasket,
        balance,
        setBalance,
        isSpeedbet,
        setIsSpeedbet,
        betBaskets_0,
        firstTimeFromJCRWRedirect
    } = useContext(MyBetSlipContext)

    const queryBetSlipData = async (options) =>{
        const { matchIds, tournIds, meetingList } = getQueryId(betBasketsRef)
        if(matchIds.length >0) {
           await loadMatchQuery({matchIds, inplayOnly: options?.inplayOnly})
        }
        if(tournIds.length > 0 && !options?.inplayOnly) {
            loadTournQuery({tournIds})
        }
        if(meetingList.length > 0){
            meetingList.forEach(item => {
                loadFoPool(item)
            })
        }
    }
    
    const loadMatchQuery = async (paras) => {
        await Promise.all([Query({ 
            query : getMatchQuery(),
            variables: getQueryVariables({page: 'ALLODDS', earlySettlementOnly: true, showAllMatch: true, ...paras})
        })])
        .then(([results]) => {
                setMatchQueryDataToBetSlip(results, betBasketsRef, paras);
                setBetBaskets(baskets=> {
                    const newBaskets = baskets?.map((betLines, index) =>{
                        return betLines?.map((betLine)=>{
                            let newBetLine = betBasketsRef.current[index].find(item  => item.id == betLine.id)
                            if(newBetLine){
                                return newBetLine
                            }
                            return betLine
                        })
                    })
                    computePanelAllupFunc(newBaskets)
                    return newBaskets
                })
        });
    }

    const loadTournQuery = (paras) => {
        Promise.all([Query({ 
            query : getTournamentQuery(),
            variables: getQueryVariables({page: 'TOURNAMENT', earlySettlementOnly: true, ...paras})
        })])
        .then(([results]) => {
            setTournQueryDataToBetSlip(results, betBasketsRef, paras.tournIds);
            setBetBaskets(baskets=> {
                const newBaskets = baskets?.map((betLines, index) =>{
                    return betLines?.map((betLine)=>{
                        let newBetLine = betBasketsRef.current[index].find(item  => item.id == betLine.id)
                        if(newBetLine){
                            return newBetLine
                        }
                        return betLine
                    })
                })
                return newBaskets
            })
        });
    }

    const loadFoPool = (meetingInfo) => {
        return Promise.all([Query({ 
            query : getFoPoolQuery(),
            variables: {
                "date": '2021-11-01',
                "venueCode": 'S1',
                "foOddsTypes": ['JKC', 'TNC']
            },
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true 
        })])
        .then(([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            setRCFODatatoBetSlip(results);
        });
    }

    return {
        queryBetSlipData
    }

}

export default useBSDataRefresh